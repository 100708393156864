import React from 'react'
import { Link } from 'react-router-dom'

/* components */
import LinkComponent from 'components/ui/Links/Link'
import Button from 'components/ui/Button/base'

/* utils */
import MQ from 'utils/mq'

/* styles */
import classes from 'pages/Mission/Overview/MainInfo/index.module.scss'

const MainInfo = ({ missionOverview }) => (
  <div className={classes.blockWithStructure}>
    <div className={classes.topBlock}>
      <div>
        <img
          className={classes.image}
          src={missionOverview.image}
          alt="logo"
        />
        <Link to={missionOverview.path} className={classes.link}>
          <span>{missionOverview.name}</span>
        </Link>
      </div>
      <MQ.SM_UP>
        <LinkComponent
          size="large"
          label="Chat with host"
          path={missionOverview.chatHost}
        />
      </MQ.SM_UP>
    </div>
    <div>
      <div className={classes.blockWithDescription}>
        <p>{missionOverview.description}</p>
      </div>
      <div className={classes.blockWithDescription}>
        <h4 className={classes.title}>What will the volunteer do? </h4>
        <p>{missionOverview.volunteerWork}</p>
      </div>
      <div className={classes.blockWithDescription}>
        <h4 className={classes.title}>Area of the mission</h4>
        <p>{missionOverview.areaOfTheMission}</p>
      </div>
      <Button type="button" text="Translate with Google Translate" variant="outlinePrimary" />
    </div>
  </div>
)

export default MainInfo
