import React from 'react'

/* components */
import DoubleOffer from 'components/ui/Offers/DoubleOffer'
import MainInfo from 'pages/Mission/Overview/MainInfo'
import Comments from 'components/common/Comments'

/* configs */
import { comments } from 'pages/Mission/configs'

/* styles */
import classes from 'pages/Mission/Overview/index.module.scss'

const MissionOverview = ({ missionOverview }) => (
  <section className={classes.sectionWrapper}>
    <MainInfo missionOverview={missionOverview} />

    <DoubleOffer />

    <Comments
      comments={comments}
      hasRating
      overallRating={4.5}
    />

  </section>
)

export default MissionOverview
