import React from 'react'

/* components */
import MissionHeader from 'pages/Mission/Header'
import Tabs from 'components/common/Tabs'
import Missions from 'pages/sheared/Missions'

/* configs */
import { tabsConfigs, missions } from 'pages/Mission/configs'

/* styles */
import classes from 'pages/Mission/index.module.scss'

const MissionPage = () => (
  <div className={classes.missionPageWrapper}>
    <div className="container">
      <div className={classes.missionPageInner}>

        <MissionHeader
          title="Wildlife Sanctuary Supporter"
          flagIcon="us"
          location="Alajuela, Costa Rica"
          volunteersCount={12}
        />

        <Tabs
          tabs={tabsConfigs}
        />

        <Missions
          items={missions}
          title="Other Missions"
          titleSpacing="my-12"
        />

      </div>
    </div>
  </div>
)

export default MissionPage
