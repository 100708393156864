import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

/* components */
import LinkComponent from 'components/ui/Links/Link'

/* utils */
import MQ from 'utils/mq'

/* styles */
import classes from 'pages/sheared/InfoBlock/index.module.scss'

const InfoBlock = ({
  title,
  subtitle,
  text,
  imageUrl,

  buttonText,
  buttonLink,
  reverse,
}) => (
  <section className={classes.infoBlockSection}>
    <div className={classes.infoBlockSectionWrapper}>
      <div className="container">
        <div className={classes.infoBlockSectionInner}>

          <div className={clsx(classes.infoBlock, reverse && classes.reverse)}>

            <div className={classes.textWrapper}>
              {subtitle && (
                <div className={classes.subtitle}>
                  {subtitle}
                </div>
              )}
              <h2 className={classes.title}>
                {title}
              </h2>
              <p className={classes.text}>
                {text}
              </p>

              <LinkComponent path={buttonLink} label={buttonText} />
            </div>

            <MQ.MD_UP>
              <div className={classes.imageWrapper}>
                <img
                  className={classes.image}
                  src={imageUrl}
                  alt={title}
                />
              </div>
            </MQ.MD_UP>

          </div>
        </div>
      </div>
    </div>
  </section>
)

InfoBlock.propTypes = {
  reverse: PropTypes.bool,
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
}

InfoBlock.defaultProps = {
  imageUrl: '',
  reverse: false,
}

export default InfoBlock
