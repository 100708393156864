import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SpriteIcon from 'components/icons/SpriteIcon'
import classes from './Social.module.scss'


const Social = (
  {
    link,
    img,
    variant,
    icon,
    spacing,
  },
) => (
  <a
    href={link}
    className={clsx(classes.socialWrapper, classes[spacing], 'd-flex align-items-center justify-content-center')}
    target="_blank"
    rel="noopener noreferrer">
    <div
      className={clsx(classes.social, classes[variant], 'd-flex align-items-center justify-content-center')}>
      <SpriteIcon name={img} size={icon} />
    </div>
  </a>
)

Social.propTypes = {
  link: PropTypes.string,
  img: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.oneOf(['linkedin', 'twitter', 'facebook', 'instagram']),
  spacing: PropTypes.oneOf(['mr-4', 'ml-4']),
}

Social.defaultProps = {}

export default Social
