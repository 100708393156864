import routes from 'constants/routes'

export const profileHost = {
  role: 'host',
  verification: false,
  name: 'John Doe',
  firstName: 'John',
  avatar: 'https://fakeimg.pl/250x100/',

  flagIcon: 'us',
  location: 'Alajuela, Costa Rica',
  birth: 'June 16th, 1989',
  phone: '336868458464',

  structureImage: 'https://fakeimg.pl/250x100/',
  structureName: 'Structure Name',
  structureDescription: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey, increases erosion of nesting beaches, and more.',
  structureLocation: 'Alajuela, Costa Rica',
  structureFlagIcon: 'us',
  structurePath: routes.structure,

  socials: {
    linkedin: {
      name: 'linkedin',
      link: 'https://www.linkedin.com/',
    },
    twitter: {
      name: 'twitter',
      link: 'https://twitter.com/',
    },
    facebook: {
      name: 'facebook',
      link: 'https://uk-ua.facebook.com/',
    },
    instagram: {
      name: 'instagram',
      link: 'https://www.instagram.com/?hl=ru',
    },
  },
}
