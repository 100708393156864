import React from 'react'
import { Link } from 'react-router-dom'
import classes from './BreadcrumbLink.module.scss'

const BreadcrumbLink = (
  {
    link,
    name,
  },
) => (
  <li className={classes.link}>
    <Link to={link}>
      <span className={classes.title}>
        {name}
      </span>
    </Link>
  </li>
)

BreadcrumbLink.propTypes = {
}

BreadcrumbLink.defaultProps = {
}

export default BreadcrumbLink
