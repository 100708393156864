import React from 'react'

import SectionTitle from 'components/ui/SectionTitle'

import PriceBlock from 'pages/CreateHost/IncomeEstimation/PriceBlock'

/* styles */
import classes from './index.module.scss'

const IncomeEstimation = () => (
  <section className={classes.incomeEstimationSection}>
    <div className={classes.incomeEstimationSectionWrapper}>
      <div className="container medium">
        <div className={classes.incomeEstimationSectionInner}>


          <div className={classes.incomeEstimationWrapper}>
            <div className={classes.incomeEstimationInner}>

              <div className={classes.incomeEstimationPriceWrapper}>
                <PriceBlock />
              </div>

              <div className={classes.incomeEstimationDisclaimerWrapper}>
                <SectionTitle item="Fees" variant="white" position="text-start" spacing="mb-4" />
                <p className={classes.incomeEstimationDisclaimer}>
                  TWAM fee of 10% will be deducted when the money will be sent to your account if your package is free
                  and people don’t add options
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
)

export default IncomeEstimation
