import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button/base'
import classes from './Clipboard.module.scss'


const Clipboard = (
  {
    value,
  },
) => {
  // TODO refactoring component
  const [copySuccess, setCopySuccess] = useState('')
  const textAreaRef = useRef(null)

  const handleClick = (event) => {
    textAreaRef.current.select()
    document.execCommand('copy')
    setCopySuccess('Copied!')
    event.target.focus()
  }

  return (
    <div>
      <input
        className={classes.text}
        ref={textAreaRef}
        defaultValue={value}
        readOnly
      />

      <Button
        type="button"
        onClick={handleClick}
        text={copySuccess || 'Copy Link'}
        spacing={classes.copy}
      />

      <Button
        type="button"
        text="Share on Facebook"
        spacing={classes.facebook}
        variant="facebook"
        nameIcon="facebook-v2"
        sizeIcon="w-20px"
      />
      <Button
        type="button"
        text="Share on Twitter"
        spacing={classes.twitter}
        nameIcon="twitter"
      />
    </div>
  )
}

Clipboard.propTypes = {
  value: PropTypes.string,
}

Clipboard.defaultProps = {}

export default Clipboard
