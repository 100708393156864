export default {
  en: {
    EnvironmentTitle: 'Environment & Ecovolunteering',
    EnvironmentDescription: 'The Twaming platform facilitate the connection between those who want to share some knowledge, skills or experiences (called Twamers) and those who want to receive them (called Twamhosts).',
  },
  fr: {
    EnvironmentTitle: 'Environnement et écovolontariat',
  },
  de: {},
}
