import React from 'react'
import { Link } from 'react-router-dom'
import LinkComponent from 'components/ui/Links/Link'
import Button from 'components/ui/Button/base'
import clsx from 'clsx'
import classes from './Structure.module.scss'


const Structure = (
  {
    image,
    chatHost,
    path,
    name,
    description,
    volunteerWork,
    areaOfTheMission,
  },
) => (
  <div className={classes.blockWithStructure}>
    <div className={clsx(classes.topBlock, 'd-flex justify-content-between align-items-center')}>
      <div>
        <img className={classes.image} src={image} alt="logo" />
        <Link to={path} className={classes.link}>
          <span>{name}</span>
        </Link>
      </div>
      <LinkComponent size="large" label="Chat with dsa" path={chatHost} />
    </div>
    <div>
      <div className={classes.blockWithDescription}>
        <p>{description}</p>
      </div>
      <div className={classes.blockWithDescription}>
        <h4 className={classes.title}>What will the volunteer do? </h4>
        <p>{volunteerWork}</p>
      </div>
      <div className={classes.blockWithDescription}>
        <h4 className={classes.title}>Area of the mission</h4>
        <p>{areaOfTheMission}</p>
      </div>
      <Button type="button" text="Translate with Google Translate" variant="outlinePrimary" />
    </div>
  </div>
)

Structure.propTypes = {}

Structure.defaultProps = {}

export default Structure
