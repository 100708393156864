import React from 'react'

/* components */
import Video from 'components/ui/Video'
import LinkComponent from 'components/ui/Links/Link'

import classes from 'pages/Profile/Volunteer/Resume/index.module.scss'

const Resume = () => (
  <div className={classes.resumeWrapper}>
    <Video
      spacing="mb-8"
    />
    <LinkComponent
      label="View Resume"
      color="secondaryColor"
      path=""
      size="maxWidth"
    />
  </div>
)

export default Resume
