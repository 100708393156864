import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import GroupSocial from 'components/ui/Links/Social/GroupSocials'
import Video from 'components/ui/Video'

/* styles */
import classes from './index.module.scss'

const SecondaryInfo = ({
  image,
  name,
  path,
  role,
  description,
  socials,
}) => (
  <div className={classes.secondaryInfoWrapper}>
    <div className={classes.secondaryInfoMetaWrapper}>
      <div className={classes.secondaryInfoUserWrapper}>
        <img
          src={image}
          className={classes.secondaryInfoUserAvatar}
          alt="avatar"
        />
        <div className={classes.secondaryInfoUserNameWrapper}>
          <Link to={path}>
            <span className={classes.secondaryInfoUserName}>
              {name}
            </span>
          </Link>
          <h5 className={classes.secondaryInfoUserRole}>
            {role}
          </h5>
        </div>
      </div>
      <p className={classes.secondaryInfoDescription}>
        {description}
      </p>
      <GroupSocial
        social={socials}
        spacing="mr-4"
      />
    </div>
    <Video />
  </div>
)

SecondaryInfo.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  path: PropTypes.string,
  role: PropTypes.string,
  description: PropTypes.string,
  socials: PropTypes.object,
}

export default SecondaryInfo
