import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'

import SectionTitle from 'components/ui/SectionTitle'

/* components */
import QuestionAnswer from './QuestionAnswer'

/* configs */
import data from './configs'

/* styles */
import classes from './index.module.scss'

const HelpCenterPage = () => {
  const renderQuestionAnswer = () => data.map((item, key) => (
    <QuestionAnswer
      key={key}
      {...item}
    />
  ))

  return (
    <div className={classes.helpCenterPageWrapper}>
      <div className="container">
        <div className={classes.helpCenterPageInner}>

          <SectionTitle item="Help Center" />
          {renderQuestionAnswer()}

        </div>
      </div>
    </div>
  )
}

export default HelpCenterPage
