import img from 'assets/images/photo.jpg'

const BreadcrumbConfig = [
  {
    id: 0,
    name: 'Theme name',
    link: '',
  },
  {
    id: 1,
    name: 'Subtheme Name',
    link: 'Link',
  },
]

const ThemeTitleConfig = [
  {
    id: 0,
    title: 'Wildlife Sanctuary Supporter',
    flagIcon: 'en',
    location: 'Alajuela, Costa Rica',
    countVolunteer: 12,
    likeState: false,
    value: 'https://www.figma.com/file/S3OhxfMYvy3ClQRSVP4uPG/Volunteering?node-id=460%3A360',
  },
]

const overviewHeader = [
  {
    id: 0,
    image: img,
    path: '',
    chatHost: '',
    name: 'Structure Name',
    description: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey, increases erosion of nesting beaches, and more.',
    volunteerWork: 'Don’t buy souvenirs or other items made from critically endangered hawksbill shell. When traveling, ask vendors what souvenirs are made of and don’t purchase items in question.',
    areaOfTheMission: 'Don’t buy souvenirs or other items made from critically endangered hawksbill shell. When traveling, ask vendors what souvenirs are made of and don’t purchase items in question.',
  },
]


export {
  BreadcrumbConfig,
  ThemeTitleConfig,
  overviewHeader,
}
