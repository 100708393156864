import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import clsx from 'clsx'

/* components */
import SectionTitle from 'components/ui/SectionTitle'
import Testimonial from 'pages/sheared/Testimonials/Testimonial'

/* utils */
import MQ from 'utils/mq'

/* constants */
import { LG_DOWN, MD_DOWN, SM_DOWN } from 'constants/screen-sizes'

/* styles */
import classes from 'pages/sheared/Testimonials/index.module.scss'
import 'pages/sheared/Testimonials/slider.scss'

const Testimonials = ({ items }) => {
  const renderTestimonials = useMemo(() => items.map((itemProps, index) => (
    <Testimonial
      key={index}
      {...itemProps}
    />
  )), [items])

  const sliderSettings = {
    className: 'testimonialsSlider',
    dots: true,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: LG_DOWN,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: MD_DOWN,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: SM_DOWN,
        settings: {
          centerMode: true,
          centerPadding: '15px',
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <section className={clsx('testimonialsSection', classes.testimonialsSection)}>
      <div className={classes.testimonialsSectionWrapper}>
        <div className="container">
          <div className={classes.testimonialsSectionInner}>

            <SectionTitle item="Testimonials" />

            <MQ.LG_UP>
              <div className={classes.testimonialsListWrapper}>
                {renderTestimonials}
              </div>
            </MQ.LG_UP>
            <MQ.LG_DOWN>
              <Slider {...sliderSettings}>
                {renderTestimonials}
              </Slider>
            </MQ.LG_DOWN>

          </div>
        </div>
      </div>
    </section>
  )
}

Testimonials.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Testimonials
