import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from 'components/ui/SectionTitle'
import FlagCountry from 'components/ui/FlagCountry'
import ModalWrapper from 'components/common/Modal'
import LikeButton from 'components/ui/Button/LikeButton'
import ShareMission from './ShareMission'


const ThemeTitle = (
  {
    title,
    location,
    flagIcon,
    countVolunteer,
    likeState,
    value,
  },
) => (

  <div>
    <SectionTitle
      item={title}
      position="text-start"
      spacing="mb-4"
    />
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex">
        <FlagCountry
          location={location}
          flagIcon={flagIcon}
          spacing="mb-0"
        />
        <ModalWrapper
          buttonValue={`${countVolunteer} volunteer`}
          buttonVariant="primaryNotBg"
          size="medium"
        />
      </div>
      <div className="d-flex">
        <LikeButton likeState={likeState} />
        <ModalWrapper
          buttonValue="Share"
          buttonVariant="primaryNotBg"
          size="medium"
          nameIcon="share">
          <ShareMission value={value} />
        </ModalWrapper>
      </div>
    </div>
  </div>
)

ThemeTitle.propTypes = {
  title: PropTypes.string,
  flagIcon: PropTypes.string,
  countVolunteer: PropTypes.number,
  likeState: PropTypes.bool,
  value: PropTypes.string,
}

ThemeTitle.defaultProps = {
  countVolunteer: 0,
}

export default ThemeTitle
