import React from 'react'
import PropTypes from 'prop-types'
import SectionTitle from 'components/ui/SectionTitle'
import Clipboard from 'components/ui/Clipboard'


const ShareMission = (
  {
    value,
  },
) => (
  <div>
    <SectionTitle item="Share Mission" />
    <Clipboard value={value} />
  </div>
)

ShareMission.propTypes = {
  value: PropTypes.string,
}

ShareMission.defaultProps = {}

export default ShareMission
