import React from 'react'

import routes from 'constants/routes'

/* components */
import MissionOverview from 'pages/Mission/Overview'
import MissionPackage from 'pages/Mission/Package'
import MissionRequirements from 'pages/Mission/Requirements'
import MissionCost from 'pages/Mission/Cost'
import MissionOptions from 'pages/Mission/Options'

/* configs */
const missionOverview = {
  image: 'https://fakeimg.pl/115x50/',
  path: routes.structure,
  chatHost: '',
  name: 'Structure Name',
  description: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey, increases erosion of nesting beaches, and more.',
  volunteerWork: 'Don’t buy souvenirs or other items made from critically endangered hawksbill shell. When traveling, ask vendors what souvenirs are made of and don’t purchase items in question.',
  areaOfTheMission: 'Don’t buy souvenirs or other items made from critically endangered hawksbill shell. When traveling, ask vendors what souvenirs are made of and don’t purchase items in question.',
}
const missionPackage = {
  items: [
    {
      title: 'Accomodation',
      description: 'We do have 4 mains chalets for volunteers, each one offering a capacity of 3 to 5 single beds. Male and Female are not mixed in the same chalet unless specific requestsYou will be provided with sheets and blankets and do not need to bring some with you. Your bed laundry will be cleaned and changed once a week. We do+provide a mosquito net, but sometime they get damaged, so please be aware that we advise you to bring one.Each chalet is equipped with one bathroom including a toilet, a sink and a shower with hot and cold water EXCEPT for chalet 2 & the cabins.',
    },
    {
      title: 'Food',
      description: 'We do have 4 mains chalets for volunteers, each one offering a capacity of 3 to 5 single beds. Male and Female are not mixed in the same chalet unless specific requestsYou will be provided with sheets and blankets and do not need to bring some with you. Your bed laundry will be cleaned and changed once a week. We do+provide a mosquito net, but sometime they get damaged, so please be aware that we advise you to bring one.Each chalet is equipped with one bathroom including a toilet, a sink and a shower with hot and cold water EXCEPT for chalet 2 & the cabins.',
    },
    {
      title: 'Local Transportation',
      description: 'We do have 4 mains chalets for volunteers, each one offering a capacity of 3 to 5 single beds. Male and Female are not mixed in the same chalet unless specific requestsYou will be provided with sheets and blankets and do not need to bring some with you. Your bed laundry will be cleaned and changed once a week. We do+provide a mosquito net, but sometime they get damaged, so please be aware that we advise you to bring one.Each chalet is equipped with one bathroom including a toilet, a sink and a shower with hot and cold water EXCEPT for chalet 2 & the cabins.',
    },
    {
      title: 'Visits',
      description: ' One visit at a local historical site is included in the package. Entry fee as well as transportation will be included.All other visits will be at your own cost. ',
    },
    {
      title: 'Training',
      description: 'We will provide a one day training the day of your arrival. This training will include Lorem Ipsum, lorem Ipsum.',
    },
    {
      title: 'Others',
      description: 'You will have free wifi and access to a washing machine for free. ',
    },
  ],
  pictures: [
    {
      image: 'https://fakeimg.pl/240x150/',
    },
    {
      image: 'https://fakeimg.pl/240x150/',
    },
    {
      image: 'https://fakeimg.pl/240x150/',
    },
    {
      image: 'https://fakeimg.pl/240x150/',
    },
  ],
}
const missionRequirements = '<p>The VCS Program rules are the backbone of the program. They define the standards and processes which all projects must follow in order to be certified. <br /><br /> The VCS Program rules are set out in a suite of documents which include four types of program documents: Requirements, Procedural, Templates and Guidance. Documents are updated periodically. Please check this page to be sure you are using the latest version of a given document.</p>'
const missionCost = {
  fullPackages: [
    {
      image: 'https://fakeimg.pl/270x160/',
      price: 300.0,
      age: 'Adult',
      days: 15,
    },
    {
      image: 'https://fakeimg.pl/270x160/',
      price: 150.0,
      age: 'Child',
      days: 15,
    },
  ],
  discounts: [
    {
      daysPeriod: '16-30',
      priceAdult: 15.0,
      priceChild: 7.5,
    },
    {
      daysPeriod: '31-60',
      priceAdult: 10.0,
      priceChild: 5.0,
    },
    {
      daysPeriod: 'More then 60',
      priceAdult: 6.0,
      priceChild: 3.0,
    },
  ],
}
const missionOptions = [
  {
    image: 'https://fakeimg.pl/270x160/',
    title: 'Option #1',
    description: 'Don’t buy souvenirs or other items made from critically endangered hawksbill shell. When traveling, ask vendors what souvenirs are made of.',
    price: '100,00',
    option: 'fixed',
  },
  {
    image: 'https://fakeimg.pl/270x160/',
    title: 'Option #2',
    description: 'Don’t buy souvenirs or other items made from critically endangered hawksbill shell. When traveling, ask vendors what souvenirs are made of. Don’t buy souvenirs or other items made from critically endangered hawksbill shell. When traveling, ask vendors what souvenirs are made of.',
    price: '25,00',
    option: 'per day',
  },
]

export const tabsConfigs = [
  {
    name: 'overview',
    title: 'Overview',
    body: <MissionOverview missionOverview={missionOverview} />,
  },
  {
    name: 'package',
    title: 'Package',
    body: <MissionPackage missionPackage={missionPackage} />,
  },
  {
    name: 'requirements',
    title: 'Requirements',
    body: <MissionRequirements missionRequirements={missionRequirements} />,
  },
  {
    name: 'cost',
    title: 'Cost',
    body: <MissionCost missionCost={missionCost} />,
  },
  {
    name: 'options',
    title: 'Options',
    body: <MissionOptions missionOptions={missionOptions} />,
  },
]

export const comments = [
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Hanna Kenter',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Adison George',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey. Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Jaylon Bator',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Wilson Mango',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Wilson Mango',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Hanna Kenter',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Adison George',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey. Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Jaylon Bator',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Wilson Mango',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Wilson Mango',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
    rating: 4,
  },
]

export const missions = [
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Animal Rescue Centre Supporter',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Teaching English to Monks',
    flagIcon: 'us',
    locationTitle: 'Kathmandu, Nepal',
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Great White Shark Conservation',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
    isEnd: true,
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Natural Horsemanship Guestranch',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
  },
]
