import React from 'react'
import PropTypes from 'prop-types'

/* components */
import VerificationStatus from 'components/common/VerificationStatus'
import FlagCountry from 'components/common/FlagCountry'
import Icon from 'components/common/Icon'

/* styles */
import classes from './index.module.scss'

const MainInfo = ({
  logo,
  name,
  website,
  location,
  verification,
  flagIcon,
}) => (
  <div className={classes.mainInfoWrapper}>
    <div className={classes.mainInfoMetaWrapper}>
      {/* eslint-disable-next-line global-require,import/no-dynamic-require */}
      <img src={require(`assets/images/${logo}.png`)} className={classes.mainInfoLogo} alt="logo" />
      <div className={classes.mainInfoMetaTextWrapper}>
        <h1 className={classes.mainInfoName}>
          {name}
        </h1>
        <FlagCountry
          locationFull={location}
          locationAbbr={flagIcon}
        />
        <a href={website} className={classes.mainInfoWebsiteLink} target="_blank" rel="noopener noreferrer">
          <Icon iconName="website" />
          <span className={classes.mainInfoWebsiteText}>
            {website}
          </span>
        </a>
      </div>
    </div>
    <VerificationStatus verified={verification} />
  </div>
)

MainInfo.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
  website: PropTypes.string,
  location: PropTypes.string,
  verification: PropTypes.bool,
  flagIcon: PropTypes.string,
}

export default MainInfo
