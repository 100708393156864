export const SIGN_IN = 'SIGN_IN'
export const SIGN_UP = 'SIGN_UP'
export const SIGN_OUT = 'SIGN_OUT'

export const GET_PROFILE = 'GET_PROFILE'

export const USER_ACTIONS = {
  SIGN_IN,
  SIGN_UP,
  SIGN_OUT,

  GET_PROFILE,
}
