import routes from 'constants/routes'

const link = routes.theme

export const items = [
  {
    name: 'education',
    title: 'Education',
    link,
  },
  {
    name: 'animation',
    title: 'Animation',
    link,
  },
  {
    name: 'health',
    title: 'Health',
    link,
  },
  {
    name: 'communication-journalism',
    title: 'Communication, Journalism',
    link,
  },
  {
    name: 'business-development',
    title: 'Business, Development',
    link,
  },
  {
    name: 'construction-rehabilitation',
    title: 'Construction, Rehabilitation',
    link,
  },
  {
    name: 'environment-ecovolunteering',
    title: 'Environment, Ecovolunteering',
    link,
  },
  {
    name: 'tourism',
    title: 'Tourism',
    link,
  },
  {
    name: 'archeology',
    title: 'Archeology',
    link,
  },
  {
    name: 'social-integration',
    title: 'Social Integration',
    link,
  },
  {
    name: 'law',
    title: 'Law',
    link,
  },
  {
    name: 'urgency',
    title: 'Urgency',
    link,
  },
]
