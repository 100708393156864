import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* components */
import Icon from 'components/common/Icon'

/* utils */
import MQ from 'utils/mq'

/* styles */
import classes from 'pages/Mission/Header/FavoritesToggler/index.module.scss'

const FavoritesToggler = ({ isFavorite }) => {
  const [favorite, setIsFavorite] = useState(isFavorite || false)

  const handleToggle = () => setIsFavorite((favorite) => !favorite)

  return (
    <div className={classes.wrapper} onClick={handleToggle} role="button">
      <div className={classes.iconWrapper}>
        {favorite ?
          <Icon iconFolder="hearts" iconName="heart-empty" />
          :
          <Icon iconFolder="hearts" iconName="heart-full" />}
      </div>
      <MQ.SM_UP>
        <div className={classes.textWrapper}>
          {favorite ? 'Remove From Favorites' : 'Add To Favorites'}
        </div>
      </MQ.SM_UP>
    </div>
  )
}

FavoritesToggler.propTypes = {
  isFavorite: PropTypes.bool,
}

FavoritesToggler.defaultProps = {
  isFavorite: false,
}

export default FavoritesToggler
