import { connectRouter } from 'connected-react-router'
import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

/* reducers */
import userReducer from 'store/reducers/user'

export default function createRootReducer(history) {
  return persistCombineReducers(
    { key: 'root', storage, blacklist: ['router'] },
    {
      router: connectRouter(history),

      user: userReducer,
    },
  )
}
