import React from 'react'
import SVG from 'react-inlinesvg'

/* eslint-disable global-require,import/no-dynamic-require */
const Icon = ({
  iconName,
  iconFolder = '',
  extension = 'svg',
  size,

  className,
}) => {
  if (!iconName) {
    return null
  }

  if (iconFolder) {
    iconFolder += '/'
  }


  if (extension === 'png') {
    const [width, height] = size.split('x')

    return (
      <img
        src={require(`assets/icons/${iconFolder}${iconName}.png`)}
        width={width}
        height={height}
        className={className}
        alt="icon"
      />
    )
  }

  return (
    <SVG
      src={require(`assets/icons/${iconFolder}${iconName}.svg`)}
      className={className}
    />
  )
}

export default Icon
