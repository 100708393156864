import React from 'react'
import PropTypes from 'prop-types'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'

/* styles */
import classes from './index.module.scss'

const BaseAccordion = ({ title, text, onClick }) => (
  <Accordion className={classes.Accordion}>
    <Card className={classes.Card}>

      <Card.Header className={classes.CardHeader}>
        <Accordion.Toggle
          onClick={onClick}
          variant="link"
          eventKey="0"
          className={classes.Button}>
          {title}
        </Accordion.Toggle>
      </Card.Header>

      <Accordion.Collapse eventKey="0">
        <Card.Body className={classes.CardBody}>
          {text}
        </Card.Body>
      </Accordion.Collapse>

    </Card>
  </Accordion>
)

BaseAccordion.propTypes = {
  title: PropTypes.element,
  text: PropTypes.element,
  onClick: PropTypes.func,
}

export default BaseAccordion
