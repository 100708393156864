import React from 'react'

/* components */
import Banner from 'pages/sheared/Banner'
import MainBannerInner from 'pages/Theme/MainBannerInner'
import MissionsCarousel from 'pages/Theme/MissionsCarousel'
import Advantages from 'pages/Theme/Advantages'
import InfoBlock from 'pages/sheared/InfoBlock'
import Categories from 'pages/sheared/Categories'
import BlocksVolunteering from 'pages/Theme/BlocksVolunteering'

/* constants */
import routes from 'constants/routes'

/* assets */
import backgroundPrimary from 'assets/images/banners/banner_environment-bg.jpg'

const ThemePage = () => (
  <>
    <Banner backgroundUrl={backgroundPrimary} rounded>
      <MainBannerInner />
    </Banner>

    <MissionsCarousel title="Protection of Animals" />
    <MissionsCarousel title="Protection of Marine Life" />
    <MissionsCarousel title="Protection of Forests" />

    <Advantages />

    <InfoBlock
      imageUrl="https://fakeimg.pl/700x392/"
      title="Twaming Platform"
      subtitle="Subtitle here"
      text="The Twaming platform facilitate the connection between those who want to share some knowledge, skills or experiences (called Twamers) and those who want to receive them (called Twamhosts)."
      buttonText="Go to TWAM"
      buttonLink={routes.createHost}
    />

    <Categories title="Other Categories" />

    <BlocksVolunteering />
  </>
)

export default ThemePage
