export default {
  en: {
    CreateHostDescriptionFirst: 'You want to receive volunteers inside your organization?',
    CreateHostDescriptionSecond: 'You want to provide package, which can become income generating activities for your organization?',
    CreateHostDescriptionTitle: 'Don’t wait anymore, create a free profile!',
    CreateHostDescriptionButton: 'Add Your Structure',
  },
  fr: {
    CreateHostDescriptionFirst: 'Vous souhaitez recevoir des bénévoles au sein de votre organisation?',
    CreateHostDescriptionButton: 'Ajoutez votre structure',
  },
  de: {},

}
