import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'

/* components */
import Input from 'components/ui/Inputs/Base'
import PasswordInput from 'components/ui/Inputs/Password'
import Button from 'components/ui/Button/base'
import SectionTitle from 'components/ui/SectionTitle'
import FacebookAuth from 'components/common/Auth/sheared/FacebookAuth'
import Icon from 'components/common/Icon'

/* actions */
import { signIn, forgotPassword, facebookAuth, prefillFacebook } from 'store/actions/user'

/* utils */
import customEvent from 'utils/custom-event'

/* constants */
import { modalVisibilityActions } from 'components/common/Modal/constants'

/* configs */
import { formFields } from 'components/common/Auth/SignIn/configs'

/* styles */
import classes from 'components/common/Auth/SignIn/index.module.scss'

const SignInForm = ({ signIn, forgotPassword, prefillFacebook }) => {

  const closeThisModal = () => customEvent.trigger(`${modalVisibilityActions.close}sign-in`)

  const socialLogin = (res) => {
    const data = {
      token: res.accessToken,
      network: 'facebook',
    }

    prefillFacebook(data)

  }

  const formikSignIn = useFormik({
    initialValues: {
      [formFields.email]: '',
      [formFields.password]: '',
    },

    validationSchema: Yup.object({
      [formFields.email]: Yup.string().email().required(),
      [formFields.password]: Yup.string().min(8).required(),
    }),

    onSubmit: (values, { resetForm }) => {
      signIn(values)

      resetForm()

      closeThisModal()
    },
  })
  const _renderSignInForm = (formik) => {
    const hasError = (fieldName) => formik.touched[fieldName] && formik.errors[fieldName]
    const emailHasError = hasError(formFields.email)
    const passwordHasError = hasError(formFields.password)

    return (
      <form onSubmit={formik.handleSubmit}>
        <SectionTitle item="Sign In" />

        <FacebookAuth
          type="signin"
          callback={socialLogin}
        />

        <Input
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.email}
          name={formFields.email}
          label={formFields.email}
          placeholder="Enter email"
          type={formFields.email}

          error={emailHasError ? formik.errors.email : null}
          errorInput={emailHasError ? 'errorInput' : null}
          errorLabel={emailHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.password}
          name={formFields.password}
          label={formFields.password}
          placeholder="Enter password"

          error={passwordHasError ? formik.errors.password : null}
          errorInput={passwordHasError ? 'errorInput' : null}
          errorLabel={passwordHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <Button
          text="Sign In"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || !formik.values.email || !formik.values.password}

          type="submit"
          size="large"
          color="btn-primary"
          spacing="mb-6"
        />

        <Button
          text="Forgot Password?"
          /* eslint-disable-next-line no-use-before-define */
          onClick={() => setIsForgotPassword(true)}

          type="button"
          size="large"
          color="primaryNotBg"
        />

      </form>
    )
  }

  const [isForgotPassword, setIsForgotPassword] = useState(false)
  const formikForgotPassword = useFormik({
    initialValues: {
      [formFields.email]: '',
    },

    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),

    onSubmit: (values, { resetForm }) => {
      forgotPassword(values)

      resetForm()

      closeThisModal()
    },
  })
  const _renderForgotPasswordForm = (formik) => {
    const hasError = (fieldName) => formik.touched[fieldName] && formik.errors[fieldName]
    const emailHasError = hasError(formFields.email)

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.goBackButton} onClick={() => setIsForgotPassword(false)} role="button">
          <Icon iconFolder="arrows" iconName="prev" />
        </div>
        <SectionTitle item="Forgot Password?" />

        <Input
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.email}
          name={formFields.email}
          label={formFields.email}
          placeholder="Enter email"
          type={formFields.email}

          error={emailHasError ? formik.errors.email : null}
          errorInput={emailHasError ? 'errorInput' : null}
          errorLabel={emailHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <Button
          text="Recover Password"
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || !formik.values.email}

          type="button"
          size="large"
          color="primary"
        />

      </form>
    )
  }

  return isForgotPassword ?
    _renderForgotPasswordForm(formikForgotPassword)
    :
    _renderSignInForm(formikSignIn)
}

const actionsToProps = { signIn, forgotPassword, facebookAuth, prefillFacebook }

export default connect(null, actionsToProps)(SignInForm)
