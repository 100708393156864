import React, { useMemo } from 'react'
import Slider from 'react-slick'

/* components */
import Icon from 'components/common/Icon'

/* styles */
import classes from 'pages/CreateHost/Advantages/AdvantagesCarousel/index.module.scss'
import 'pages/CreateHost/Advantages/AdvantagesCarousel/slider.scss'

const AdvantagesCarousel = ({ items }) => {
  const PrevArrow = (props) => {
    const { className, onClick } = props

    return (
      <div onClick={onClick} className={className} role="button">
        <Icon iconFolder="arrows" iconName="prev" />
      </div>
    )
  }
  const NextArrow = (props) => {
    const { className, onClick } = props

    return (
      <div onClick={onClick} className={className} role="button">
        <Icon iconFolder="arrows" iconName="next" />
      </div>
    )
  }

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }

  const renderSliderItems = useMemo(() => items.map((item, index) => (
    <div className={classes.slideWrapper} key={index}>
      <img
        className={classes.slideImage}
        src={item.img}
        alt=""
      />
      <div className={classes.slideContent}>
        <h1 className={classes.slideTitle}>
          {item.title}
        </h1>
        <p className={classes.slideDescription}>
          {item.description}
        </p>
      </div>
    </div>
  )), [items])

  return (
    <Slider {...sliderSettings}>
      {renderSliderItems}
    </Slider>
  )
}

export default AdvantagesCarousel
