import React from 'react'
import PropTypes from 'prop-types'
import SelectCount from 'components/ui/Dropdown/Count/SelectCount'
import classes from './Menu.module.scss'


const Menu = (
  {
    countAdults,
    countChildren,
    onChange,
  },
) => (
  <>
    <div className="d-flex justify-content-around">
      <SelectCount
        min={1}
        title="Adults"
        name="countAdults"
        value={countAdults}
        onChange={onChange}
      />
      <SelectCount
        title="Children"
        name="countChildren"
        value={countChildren}
        onChange={onChange}
      />
    </div>
    <div className={classes.description}>
      Kids below 12 years old pay half price
    </div>
  </>

)

Menu.propTypes = {
  countAdults: PropTypes.number,
  countChildren: PropTypes.number,
  onChange: PropTypes.func,
}

Menu.defaultProps = {}

export default Menu
