export default {
  en: {
    missionSearchTitle: 'Find In One Click Your Future International Volunteering Mission',
    locationLabel: 'Location',
    ArrivalLabel: 'Arrival',
    DepartureLabel: 'Departure',
    ThemeLabel: 'Theme',
    NumberOfVolunteersLabel: 'Number of Volunteers',
    StartSearchingButton: 'Start Searching',
    Book: 'Book',
  },
  fr: {
    missionSearchTitle: 'Trouvez en un clic votre future mission de volontariat international',
    locationLabel: 'Emplacement',
    ArrivalLabel: 'Arrivée',
    DepartureLabel: 'Départ',
    ThemeLabel: 'Thème',
    NumberOfVolunteersLabel: 'Nombre de volontaires',
    StartSearchingButton: 'Lancer la recherche',
  },
  de: {},
}
