import React from 'react'
import Slider from 'react-slick'
import clsx from 'clsx'

/* components */
import Category from 'pages/sheared/Categories/Category'
import SectionTitle from 'components/ui/SectionTitle'

/* utils */
import MQ from 'utils/mq'

/* constants */
import { MD_DOWN, SM_DOWN } from 'constants/screen-sizes'

/* configs */
import { items } from 'pages/sheared/Categories/configs'

/* styles */
import classes from 'pages/sheared/Categories/index.module.scss'
import 'pages/sheared/Categories/slider.scss'

const Categories = ({ title }) => {
  const renderCategories = (items) => items.map((itemProps, index) => (
    <Category
      key={index}
      {...itemProps}
    />
  ))

  function split(array, elementsCountInEachArray) {
    const [...arr] = array
    const res = []
    while (arr.length) {
      res.push(arr.splice(0, elementsCountInEachArray))
    }
    return res
  }

  const categoriesForSlider = split(items, 4)

  const renderCategoriesForSlider = () => categoriesForSlider.map((group, index) => (
    <div key={index}>
      {renderCategories(group)}
    </div>
  ))

  const sliderSettings = {
    className: 'categoriesSlider',
    dots: true,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: MD_DOWN,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: SM_DOWN,
        settings: {
          centerMode: true,
          centerPadding: '15px',
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <section className={clsx('categoriesSection', classes.categoriesSection)}>
      <div className={classes.categoriesSectionWrapper}>
        <div className="container">
          <div className={classes.categoriesSectionInner}>

            {title && (
              <SectionTitle item={title} />
            )}

            <MQ.SM_UP>
              <div className={classes.categoriesListWrapper}>
                {renderCategories(items)}
              </div>
            </MQ.SM_UP>
            <MQ.SM_DOWN>
              <Slider {...sliderSettings}>
                {renderCategoriesForSlider()}
              </Slider>
            </MQ.SM_DOWN>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Categories
