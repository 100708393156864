import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/ui/Button/base'


const LikeButton = (
  {
    likeState,
  },
) => {
  const [likeToggle, setLikeToggle] = useState(likeState)

  const handleClick = () => {
    setLikeToggle((likeToggle) => !likeToggle)
  }

  return (
    <Button
      type="button"
      onClick={handleClick}
      nameIcon="heart"
      text={likeToggle ? 'Remove From Favorites' : 'Add To Favorites'}
      variant="primaryNotBg"
    />
  )
}

LikeButton.propTypes = {
  likeState: PropTypes.bool,
}

LikeButton.defaultProps = {
  likeState: true,
}

export default LikeButton
