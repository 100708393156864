import React from 'react'

/* styles */
import classes from 'pages/NotFoundPage/index.module.scss'
import LinkComponent from '../../components/ui/Links/Link'

const NotFoundPage = () => (
  <div className={classes.notFoundPageWrapper}>
    <div className="container">
      <div className={classes.notFoundPageInner}>

        <div className={classes.notFoundPageTitle}>
          404
        </div>

        <div className={classes.notFoundPageText}>
          Page not found
        </div>

        <LinkComponent
          path="/"
          label="Go to Home page"
        />

      </div>
    </div>
  </div>
)

export default NotFoundPage
