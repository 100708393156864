import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import VerificationStatus from 'components/common/VerificationStatus/translates'

import navLinks from 'components/partials/sheared/NavLinks/translates'
import TwamMission from 'components/ui/Banner/TwamMission/translations'
import Environment from 'components/ui/Banner/EnvironmentEcovolunteering/translations'
import CreateHost from 'components/ui/Banner/CreateHost/translations'

import MissionSearch from 'modules/pages/Home/sections/MissionSearch/translations'
import MissionTab from 'modules/pages/Mission/sections/BodyMission/Tabs/translations'

import HelpCenterPage from 'pages/HelpCenter/translates'

i18n
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        common: {
          ...VerificationStatus.en,

          ...navLinks.en,
          ...TwamMission.en,
          ...Environment.en,
          ...CreateHost.en,
          ...HelpCenterPage.en,
          ...MissionSearch.en,
          ...MissionTab.en,
        },
      },
      fr: {
        common: {
          ...VerificationStatus.fr,

          ...navLinks.fr,
          ...TwamMission.fr,
          ...Environment.fr,
          ...CreateHost.fr,
          ...HelpCenterPage.fr,
          ...MissionSearch.fr,
          ...MissionTab.fr,
        },
      },
      de: {
        common: {
          ...VerificationStatus.de,

          ...navLinks.de,
          ...TwamMission.de,
          ...Environment.de,
          ...CreateHost.de,
          ...HelpCenterPage.de,
          ...MissionSearch.de,
          ...MissionTab.de,
        },
      },
    },
    fallbackLng: 'en',
    lng: 'en',
    debug: false,
    ns: ['common'],
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  })


export default i18n
