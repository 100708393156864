import React from 'react'
import PropTypes from 'prop-types'


const Header = (
  {
    countAdults,
    countChildren,
  },
) => (
  <span>{countAdults} Adult + {countChildren} Children</span>
)

Header.propTypes = {
  countAdults: PropTypes.number,
  countChildren: PropTypes.number,
}

Header.defaultProps = {}

export default Header
