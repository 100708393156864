import React from 'react'
import PropTypes from 'prop-types'
import SpriteIcon from 'components/icons/SpriteIcon'
import clsx from 'clsx'
import classes from './FlagCountry.module.scss'

const FlagCountry = (
  {
    flagIcon,
    location,
    spacing,
  },
) => (
  <div className={clsx(classes.location, classes[spacing], 'd-flex align-items-center')}>
    <SpriteIcon name={flagIcon} />
    <h4 className={classes.text}>
      {location}
    </h4>
  </div>
)

FlagCountry.propTypes = {
  flagIcon: PropTypes.string,
  location: PropTypes.string,
  spacing: PropTypes.oneOf(['mb-6', 'mb-0']),
}

FlagCountry.defaultProps = {
  spacing: 'mb-6',
}

export default FlagCountry
