export default {
  home: '/',
  helpCenter: '/help-center',
  createHost: '/create-host',
  structure: '/structure',
  profileVolunteer: '/profile-volunteer',
  profileHost: '/profile-host',
  theme: '/theme',
  mission: '/mission',

  signIn: ['/sign-in', '/signin', '/login'],
  signUp: ['/sign-up', '/signup', '/register'],

  dashboard: '/dashboard',
}
