import React, { useState } from 'react'

/* components */
import Comment from 'components/common/Comments/Comment'
import SectionTitle from 'components/ui/SectionTitle'
import StarsRating from 'components/common/StarsRating'

/* styles */
import classes from 'components/common/Comments/index.module.scss'

const Comments = ({ comments, hasRating = false, overallRating }) => {

  const [showAll, setShowAll] = useState(false)

  const partOfComments = comments.slice(0, 4)
  const commentsToShow = showAll ? comments : partOfComments

  const renderComment = () => commentsToShow.map((itemProps, index) => (
    <Comment
      key={index}
      hasRating={hasRating}
      {...itemProps}
    />
  ))

  return (
    <div className={classes.commentsWrapper}>

      <div className={classes.commentsTopBlock}>
        <div className={classes.commentsTitleWrapper}>
          <SectionTitle
            item="Comments"
            position="text-start"
          />
          {hasRating && (
            <div className={classes.overallRatingWrapper}>
              <StarsRating rating={overallRating} />
            </div>
          )}
        </div>

        {!showAll && comments.length > 4 && (
          <div className={classes.commentsViewAll} onClick={() => setShowAll(true)} role="button">
            View All Comments ({comments.length})
          </div>
        )}
      </div>

      <div className={classes.commentsListWrapper}>
        {renderComment()}
      </div>

    </div>
  )
}

export default Comments
