import React from 'react'

import SectionTitle from 'components/ui/SectionTitle'

/* styles */
import classes from 'pages/Home/MainBannerInner/index.module.scss'


const MainBannerInner = () => (
  <div className={classes.bannerWrapper}>
    <div className={classes.bannerInner}>
      <SectionTitle
        item="Find In One Click Your Future International Volunteering Mission"
        variant="white"
        spacing="mb-4"
      />
      <p className={classes.bannerText}>
        The Twaming platform facilitate the connection between those who want to share some knowledge, skills or experiences (called Twamers) and those who want to receive them (called Twamhosts).
      </p>
    </div>
  </div>
)

export default MainBannerInner
