import React from 'react'
import PropTypes from 'prop-types'

/* styles */
import classes from 'pages/Mission/Cost/DiscountItem/index.module.scss'

const DiscountItem = ({
  daysPeriod,
  priceAdult,
  priceChild,
}) => (
  <div className={classes.wrapper}>

    <div className={classes.daysPeriod}>
      {daysPeriod} days
    </div>

    <div className={classes.infoWrapper}>
      <div className={classes.infoBlockWrapper}>
        <h2 className={classes.price}>€&nbsp;{priceAdult.toFixed(2)}</h2>
        <h4 className={classes.age}>Adult</h4>
        <h4 className={classes.days}>per&nbsp;day</h4>
      </div>
      <div className={classes.infoBlockWrapper}>
        <h2 className={classes.price}>€&nbsp;{priceChild.toFixed(2)}</h2>
        <h4 className={classes.age}>Child</h4>
        <h4 className={classes.days}>per&nbsp;day</h4>
      </div>
    </div>

  </div>
)

DiscountItem.propTypes = {
  daysPeriod: PropTypes.string,
  priceAdult: PropTypes.number,
  priceChild: PropTypes.number,
}

export default DiscountItem
