import React from 'react'
import PropTypes from 'prop-types'
import DoubleOffer from 'components/ui/Offers/DoubleOffer'
import OverviewHeader from './OverviewHeader'
import classes from './Overview.module.scss'


const Overview = (
  {
    itemsStructure,
  },
) => (
  <section className={classes.sectionOverview}>
    <OverviewHeader itemsStructure={itemsStructure} />
    <DoubleOffer />
  </section>
)

Overview.propTypes = {
  itemsStructure: PropTypes.array,
}

Overview.defaultProps = {
  itemsStructure: [],
}

export default Overview
