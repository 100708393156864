import React, { useEffect } from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

/* components */
import Header from 'components/partials/Header'
import Footer from 'components/partials/Footer'

/* routes-model */
import AppModel from 'routes/app-model'

// TODO probably it can be used not via jsx
function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = () => (
  <BrowserRouter>
    <ScrollToTop />

    <Header />

    <AppModel />

    <Footer />

    <ToastContainer
      hideProgressBar
      pauseOnHover={false}
      position="bottom-left"
    />

  </BrowserRouter>
)

export default App
