import React from 'react'

/* components */
import MainInfo from 'pages/Profile/sheared/MainInfo'
import SecondaryInfo from 'pages/Profile/sheared/SecondaryInfo'
import StructureInfo from 'pages/Profile/Host/StructureInfo'

/* configs */
import { profileHost } from 'pages/Profile/Host/configs'

/* styles */
import classes from 'pages/Profile/Host/index.module.scss'

const ProfileHostPage = () => (
  <section className={classes.profileHostSection}>
    <div className={classes.profileHostSectionWrapper}>
      <div className="container">
        <div className={classes.profileHostSectionInner}>

          <div className={classes.profileHostSectionInfoWrapper}>
            <MainInfo
              avatar={profileHost.avatar}
              name={profileHost.name}
              firstName={profileHost.firstName}

              role={profileHost.role}
              verification={profileHost.verification}
            />

            <SecondaryInfo
              location={profileHost.location}
              flagIcon={profileHost.flagIcon}
              birth={profileHost.birth}
              phone={profileHost.phone}

              socials={profileHost.socials}
            />
          </div>

          <div className={classes.profileHostSectionStructureWrapper}>
            <StructureInfo
              structureImage={profileHost.structureImage}
              structureName={profileHost.structureName}
              structureDescription={profileHost.structureDescription}
              structureLocation={profileHost.structureLocation}
              structureFlagIcon={profileHost.structureFlagIcon}
              structurePath={profileHost.structurePath}
            />
          </div>

        </div>
      </div>
    </div>
  </section>
)

export default ProfileHostPage
