import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/ui/Button/base'
import Input from 'components/ui/Inputs/Base'
import classes from './SelectCount.module.scss'

const SelectCount = (
  {
    value,
    min,
    title,
    name,
    onChange,
  },
) => {

  const isMinValue = useMemo(
    () => value === min,
    [value, min],
  )

  const handleDecreaseChildren = () => {
    if (value - 1 >= min) {
      onChange({ target: { name, value: Number.parseInt(value) - 1 } })
    }
  }

  const handleIncreaseChildren = () => {
    onChange({ target: { name, value: Number.parseInt(value) + 1 } })
  }

  const handleChange = ({ target: { name, value } }) => {
    if (value >= min) {
      onChange({ target: { name, value: Number.parseInt(value) } })
    }
  }

  return (
    <div>
      <span className="d-flex justify-content-center">
        {title}
      </span>
      <div className="d-flex align-items-center">
        <Button
          onClick={handleDecreaseChildren}
          text="-"
          type="button"
          disabled={isMinValue}
          className={classes.button}
          variant={isMinValue ? 'disableCircle' : 'circle'}
          size="circleSize"
        />
        <Input
          name={name}
          type="number"
          value={value}
          size="numberInput"
          spacing="mb-0"
          onChange={handleChange}
        />
        <Button
          onClick={handleIncreaseChildren}
          text="+"
          type="button"
          className={classes.button}
          variant="circle"
          size="circleSize"
        />
      </div>
    </div>
  )
}

SelectCount.propTypes = {
  value: PropTypes.number,
  min: PropTypes.number,
  title: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

SelectCount.defaultProps = {
  min: 0,
}

export default SelectCount
