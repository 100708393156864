import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import './Select.scss'


const SelectComponent = (
  {
    select,
    optionSelect,
    label,
    labelColor,
    options,
  },
) => (
  <div className="d-flex flex-column">
    <span className={labelColor}>
      {label}
    </span>
    <Select
      components={{
        IndicatorSeparator: () => null,
      }}
      options={options}
      className={select}
      classNamePrefix={optionSelect}
    />
  </div>
)

SelectComponent.propTypes = {
  select: PropTypes.oneOf(['primary-select', 'custom']),
  optionSelect: PropTypes.oneOf(['primary-option', 'custom']),
  labelColor: PropTypes.string,
  options: PropTypes.array,
}

SelectComponent.defaultProps = {
  select: 'primary-select',
  optionSelect: 'primary-option',
  label: '',
  labelColor: 'primary-label',
  options: [],
}

export default SelectComponent
