import React from 'react'

/* components */
import Banner from 'pages/sheared/Banner'
import MainBannerInner from 'pages/CreateHost/MainBannerInner'
import Advantages from 'pages/CreateHost/Advantages'
import Testimonials from 'pages/sheared/Testimonials'
import Steps from 'pages/CreateHost/Steps'
import IncomeEstimation from 'pages/CreateHost/IncomeEstimation'
import Missions from 'pages/sheared/Missions'

/* configs */
import { advantages, testimonials, missions } from 'pages/CreateHost/configs'

/* assets */
import bannerPrimary from 'assets/images/banners/banner_createhost_bg.jpg'
import bannerIncomeEstimation from 'assets/images/estimateIncomeBg.jpg'

const CreateHostPage = () => (
  <>
    <Banner backgroundUrl={bannerPrimary}>
      <MainBannerInner />
    </Banner>

    <Advantages items={advantages} />

    <Testimonials items={testimonials} />

    <Steps />

    <Banner backgroundUrl={bannerIncomeEstimation}>
      <IncomeEstimation />
    </Banner>

    <br /><br /><br />
    <Missions items={missions} title="Missions You Can Provide" />
    <br /><br /><br />

  </>
)

export default CreateHostPage
