import React from 'react'
import PropTypes from 'prop-types'

import VerificationStatus from 'components/common/VerificationStatus'
import LinkComponent from 'components/ui/Links/Link'

/* utils */
import MQ from 'utils/mq'

/* styles */
import classes from 'pages/Profile/sheared/MainInfo/index.module.scss'

const MainInfo = ({
  avatar,
  name,
  firstName,

  role,
  verification,
}) => (
  <div className={classes.mainInfoWrapper}>

    <div className={classes.mainInfoMeta}>
      <img
        src={avatar}
        className={classes.mainInfoMetaAvatar}
        alt="person"
      />
      <div className={classes.mainInfoMetaNameWrapper}>
        <div className={classes.mainInfoMetaName}>
          {name}
        </div>
        <div className={classes.mainInfoMetaRole}>
          {role}
        </div>
      </div>
    </div>

    <div className={classes.mainInfoActions}>
      <VerificationStatus
        verified={verification}
        spacing="mb-4"
      />
      <LinkComponent
        label={(
          <>
            <MQ.SM_UP>Chat with {firstName}</MQ.SM_UP>
            <MQ.SM_DOWN>Chat</MQ.SM_DOWN>
          </>
        )}
        size="maxWidth"
        path=""
      />
    </div>

  </div>
)

MainInfo.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  firstName: PropTypes.string,

  role: PropTypes.string,
  verification: PropTypes.bool,
}


export default MainInfo
