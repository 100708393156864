import React from 'react'
import Blocks from './Block'
import classes from './index.module.scss'
import {
  blockVolunteeringFifth,
  blockVolunteeringFirst,
  blockVolunteeringFourth,
  blockVolunteeringSecond,
  blockVolunteeringSixth,
  blockVolunteeringThird,
} from './config'

const BlocksVolunteering = () => (
  <section className={classes.sectionBlocksVolunteering}>
    <div className="container">
      <div className="d-flex justify-content-between">
        <div>
          <Blocks items={blockVolunteeringFirst} title="Block #1" />
        </div>
        <div>
          <Blocks items={blockVolunteeringSecond} title="Block #2" />
          <Blocks items={blockVolunteeringThird} title="Block #3" />
        </div>
        <div>
          <Blocks items={blockVolunteeringFourth} title="Block #4" />
        </div>
        <div>
          <Blocks items={blockVolunteeringFifth} title="Block #5" />
          <Blocks items={blockVolunteeringSixth} title="Block #5" />
        </div>
      </div>
    </div>
  </section>
)

export default BlocksVolunteering
