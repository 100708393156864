export const steps = [
  {
    title: 'Step 1',
    text: 'The Twaming platform facilitate the connection between',
    img: 'https://fakeimg.pl/80/',
  },
  {
    title: 'Step 2',
    text: 'The Twaming platform facilitate the connection between',
    img: 'https://fakeimg.pl/80/',
  },
  {
    title: 'Step 3',
    text: 'The Twaming platform facilitate the connection between',
    img: 'https://fakeimg.pl/80/',
  },
  {
    title: 'Step 4',
    text: 'The Twaming platform facilitate the connection between',
    img: 'https://fakeimg.pl/80/',
  },
  {
    title: 'Step 5',
    text: 'The Twaming platform facilitate the connection between',
    img: 'https://fakeimg.pl/80/',
  },
  {
    title: 'Step 6',
    text: 'The Twaming platform facilitate the connection between',
    img: 'https://fakeimg.pl/80/',
  },
]
