import updateObject from 'store/reducers/helpers/update-object'
import { USER_ACTIONS } from 'store/constants'

const initialState = {
  authorization: null,
  id: null,
  profileId: null,
  firstName: null,
  lastName: null,
  avatar: null,
  completeness: {},
  references: {},
  facebook: null,
  twitter: null,
  instagram: null,
  skype: null,
  website: null,
  unreadMessages: null,
  verification: null,
  coordinates: null,
  runTour: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_ACTIONS.SIGN_IN:
      return updateObject(state, {
        authorization: action.payload.authorization,
        id: action.payload.id,
        firstName: action.payload.first_name,
        lastName: action.payload.last_name,
        avatar: action.payload.avatar,
        unreadMessages: action.payload.unread_messages,
        verification: action.payload.verification,
      })

    case USER_ACTIONS.GET_PROFILE:
      return updateObject(state, {
        profileId: action.payload.id,
        completeness: action.payload.completeness,
        references: action.payload.references,
        coordinates: action.payload.coordinates,
        facebook: action.payload.facebook,
        twitter: action.payload.twitter,
        instagram: action.payload.instagram,
        skype: action.payload.skype,
        website: action.payload.website,
      })

    case USER_ACTIONS.SIGN_OUT:
      return initialState

    default:
      return state
  }
}
