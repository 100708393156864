import React from 'react'


import SectionTitle from 'components/ui/SectionTitle'
import Advantage from './Advantage'


import classes from './index.module.scss'

import { advantages } from './configs'


const Advantages = () => {

  const renderAdvantages = () => advantages.map((itemProps, index) => (
    <Advantage
      key={index}
      {...itemProps}
    />
  ))


  return (
    <section className={classes.advantagesSection}>
      <div className={classes.advantagesSectionWrapper}>
        <div className="container">
          <div className={classes.advantagesSectionInner}>

            <SectionTitle item="Advantages" />

            <div className={classes.advantagesListWrapper}>
              {renderAdvantages()}
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Advantages
