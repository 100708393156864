export default {
  en: {
    Volunteering: 'Volunteering',
    SolidarityTripes: 'Solidarity Tripes',
    Projects: 'Projects',
    Events: 'Events',
    Sensitization: 'Sensitization',
    Support: 'Support',
    Blog: 'Blog',
  },
  fr: {
    Volunteering: 'Volontariat',
    SolidarityTripes: 'Voyages solidaires',
    Projects: 'Project',
    Events: 'Evenements',
    Sensitization: 'Sensibilisation',
    Support: 'Soutiens',
    Blog: 'Blog',
  },
  de: {
    Volunteering: 'Freiwilligenarbeit',
    SolidarityTripes: 'Solidaritätsreisen',
    Projects: 'Projekte',
    Events: 'Veranstaltungen',
    Sensitization: 'Sensibilisierung',
    Support: 'Unterstützung',
    Blog: 'Blog',
  },
}
