export const advantages = [
  {
    title: 'Advantages',
    description: 'The Twaming Platform Facilitate',
    img: 'https://fakeimg.pl/300/',
  },
  {
    title: 'Lorem',
    description: 'Lorem Iptum',
    img: 'https://fakeimg.pl/300/',
  },
  {
    title: 'Duis',
    description: 'Duis Aute Irure',
    img: 'https://fakeimg.pl/300/',
  },
]

export const testimonials = [
  {
    id: 0,
    rating: 4,
    title: 'Horse Sanctuary Student',
    text: 'It was an unforgettable trip. The experience Clare and Chico have with horses and natural horsemanship is huge. They really know how to train them in a very gentle and stress-free way. ',
    img: 'https://fakeimg.pl/250x100/',
    name: 'Rayna Baptista',
    date: 'Dec 24th, 2019',
  },
  {
    id: 1,
    rating: 4.5,
    title: 'Baboon Orphan Caretaker',
    text: 'I just came back from a 3 weeks stay at CARE and I cannot describe how much I enjoyed my stay there. The people are amazing and take care of the volunteers extremely well.',
    img: 'https://fakeimg.pl/250x100/',
    name: 'Randy Dokidis',
    date: 'Jan 16th, 2018',
  },
  {
    id: 2,
    rating: 1,
    title: 'Great Barrier Reef Turtle...',
    text: 'I was there on placement and had the most amazing time- We learnt so much about turtles and got lots of hands-on experience feeding and scrubbing them. ',
    img: 'https://fakeimg.pl/250x100/',
    name: 'Ann Donin',
    date: 'Mar 12th, 2019',
  },
  {
    id: 3,
    rating: 3,
    title: 'Wildlife Caretaker',
    text: 'I stayed for a month over there and it was awesome! Kathya and John are very nice people and they helped me a lot. ',
    img: 'https://fakeimg.pl/250x100/',
    name: 'Madelyn Wester...',
    date: 'Aug 1st, 2017',
  },
]

export const missions = [
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Animal Rescue Centre Supporter',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Teaching English to Monks',
    flagIcon: 'us',
    locationTitle: 'Kathmandu, Nepal',
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Great White Shark Conservation',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
    isEnd: true,
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Natural Horsemanship Guestranch',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
  },
]
