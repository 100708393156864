import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/* configs */
import { menuItems } from './configs'

/* styles */
import classes from './index.module.scss'

const NavLinks = () => {
  const { t } = useTranslation('common')

  const renderNavLink = menuItems.map((menuItem) => (
    <li className={classes.navlinksListItem} key={menuItem.id}>
      <Link to={menuItem.path} className={classes.navlinksListItemLink}>
        {t(menuItem.label)}
      </Link>
    </li>
  ))

  return (
    <ul className={classes.navlinksList}>
      {renderNavLink}
    </ul>
  )
}

export default NavLinks
