import React from 'react'
import Input from 'components/ui/Inputs/Base'
import { useFormik } from 'formik'
import DropdownCount from 'components/ui/Dropdown/Count'
import { useTranslation } from 'react-i18next'
import Button from 'components/ui/Button/base'
import classes from './SearchBook.module.scss'


const SearchBook = () => {
  const { t } = useTranslation('common')

  const form = useFormik({
    initialValues: {
      countAdults: 1,
      countChildren: 0,
    },
    onSubmit: () => {
    },
  })
  return (
    <div className={classes.blockWithForm}>
      <form onSubmit={form.handleSubmit}>
        <div className={classes.wrapperInputs}>
          <div className={classes.blockWithInput}>
            <Input
              id="arrival"
              onChange={form.handleChange}
              label={t('ArrivalLabel')}
              placeholder="Enter date"
            />
          </div>
          <div className={classes.blockWithInput}>
            <Input
              id="departure"
              onChange={form.handleChange}
              label={t('DepartureLabel')}
              placeholder="Enter date"
              value="Dec 29th, 2019"
            />
          </div>
        </div>
        <div className={classes.blockWithDropdown}>
          <DropdownCount
            label={t('NumberOfVolunteersLabel')}
            onChange={form.handleChange}
            values={form.values}
            colorLabel="black"
          />
        </div>
        <Button
          text={t('Book')}
          size="MissionSearchBtn"
        />
      </form>
      <div className={classes.footer}>
        <p>Package includes: Mission, Training, Food, Accomodation, Visits</p>
      </div>
    </div>
  )
}

SearchBook.propTypes = {}

SearchBook.defaultProps = {}

export default SearchBook
