import React, { useMemo } from 'react'

/* components */
import DoubleOffer from 'components/ui/Offers/DoubleOffer'
import FullPackageItem from 'pages/Mission/Cost/FullPackageItem'
import DiscountItem from 'pages/Mission/Cost/DiscountItem'

/* styles */
import classes from 'pages/Mission/Cost/index.module.scss'

const Cost = ({ missionCost }) => {
  const renderFullPackageItems = useMemo(() => missionCost.fullPackages.map((itemProps, index) => (
    <FullPackageItem
      key={index}
      {...itemProps}
    />
  )), [missionCost.fullPackages])

  const renderDiscounts = useMemo(() => missionCost.discounts.map((itemProps, index) => (
    <DiscountItem
      key={index}
      {...itemProps}
    />
  )), [missionCost.discounts])

  return (
    <section className={classes.sectionWrapper}>

      <div className={classes.sectionBlockWrapper}>
        <h2 className={classes.sectionBlockTitle}>
          Full Package
        </h2>
        <div className={classes.sectionBlockInner}>
          {renderFullPackageItems}
        </div>
      </div>

      <section className={classes.sectionBlockWrapper}>
        <h2 className={classes.sectionBlockTitle}>
          Discounts
        </h2>
        <div className={classes.sectionBlockInner}>
          {renderDiscounts}
        </div>
      </section>

      <DoubleOffer />

    </section>
  )
}


export default Cost
