import React from 'react'

/* components */
import Banner from 'pages/sheared/Banner'
import MainBannerInner from 'pages/Home/MainBannerInner'
import TwamMission from 'pages/Home/TwamMission'
import Categories from 'pages/sheared/Categories'
import Missions from 'pages/sheared/Missions'
import InfoBlock from 'pages/sheared/InfoBlock'
import Testimonials from 'pages/sheared/Testimonials'

/* constants */
import routes from 'constants/routes'

/* configs */
import { missions, testimonials } from 'pages/Home/configs'

/* styles */
import classes from 'pages/Home/index.module.scss'

/* assets */
import bannerPrimary from 'assets/images/banners/banner_homepage_bg.jpg'
import bannerSecondary from 'assets/images/backgroundSectionTwamMission.jpg'

const HomePage = () => (
  <div className={classes.homePageWrapper}>
    <div className={classes.homePageInner}>

      <Banner backgroundUrl={bannerPrimary} rounded>
        <MainBannerInner />
      </Banner>

      <Categories />

      <Banner backgroundUrl={bannerSecondary} spacing="medium">
        <TwamMission />
      </Banner>

      <Missions
        items={missions}
        title="Mission Examples"
        titleSpacing="my-12"
      />

      <InfoBlock
        reverse
        imageUrl="https://fakeimg.pl/700x392/"
        title="Twaming Platform"
        subtitle="Subtitle here"
        text="The Twaming platform facilitate the connection between those who want to share some knowledge, skills or experiences (called Twamers) and those who want to receive them (called Twamhosts)."
        buttonText="Go to TWAM"
        buttonLink={routes.createHost}
      />

      <Testimonials
        items={testimonials}
      />

      <InfoBlock
        imageUrl="https://fakeimg.pl/700x392/"
        title="TWAM Organizing"
        text="The Twaming platform facilitate the connection between those who want to share some knowledge, skills or experiences (called Twamers) and those who want to receive them (called Twamhosts)."
        buttonText="Learn More"
        buttonLink={routes.createHost}
      />

    </div>
  </div>
)

export default HomePage
