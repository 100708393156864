import React from 'react'

/*  components */
import MainInfo from 'pages/Structure/MainInfo'
import SecondaryInfo from 'pages/Structure/SecondaryInfo'
import Missions from 'pages/sheared/Missions'
import Comments from 'components/common/Comments'

/* configs */
import { structureMainInfo, structureSecondaryInfo, missions, comments } from 'pages/Structure/configs'

/* styles */
import classes from 'pages/Structure/index.module.scss'

const StructurePage = () => (
  <>

    <section className={classes.structureSection}>
      <div className={classes.structureSectionWrapper}>
        <div className="container">
          <div className={classes.structureSectionInner}>

            <MainInfo
              logo={structureMainInfo.logo}
              name={structureMainInfo.name}
              website={structureMainInfo.website}
              location={structureMainInfo.location}
              verification={structureMainInfo.verification}
              flagIcon={structureMainInfo.flagIcon}
            />

            <SecondaryInfo
              image={structureSecondaryInfo.image}
              name={structureSecondaryInfo.name}
              path={structureSecondaryInfo.path}
              role={structureSecondaryInfo.role}
              description={structureSecondaryInfo.description}
              socials={structureSecondaryInfo.socials}
            />
            <Missions
              title="Missions Offered By This Structure"
              items={missions}
            />

            <Comments
              comments={comments}
            />

          </div>
        </div>
      </div>
    </section>
  </>
)

export default StructurePage
