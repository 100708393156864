import React from 'react'
import PropTypes from 'prop-types'

import GroupSocial from 'components/ui/Links/Social/GroupSocials'
/* components */
import FlagCountry from 'components/common/FlagCountry'

/* styles */
import classes from 'pages/Profile/sheared/SecondaryInfo/index.module.scss'

const SecondaryInfo = ({
  location,
  flagIcon,
  birth,
  phone,

  socials,
  description,
}) => (
  <div className={classes.secondaryInfoWrapper}>
    <div className={classes.secondaryInfoGeneralWrapper}>
      <div>
        <FlagCountry
          locationFull={location}
          locationAbbr={flagIcon}
        />
        <h4 className={classes.secondaryInfoBirth}>
          Date of Birth: <span className={classes.fw600}>{birth}</span>
        </h4>
        <h4 className={classes.secondaryInfoBirth}>
          Phone Number: <span className={classes.fw600}>+{phone}</span>
        </h4>
      </div>
      <GroupSocial
        social={socials}
        spacing="mr-4"
      />
    </div>
    {description && (
      <div className={classes.secondaryInfoDescriptionWrapper}>
        <h4 className={classes.secondaryInfoDescriptionTitle}>
          What Do I Want To Volunteer?
        </h4>
        <div className={classes.secondaryInfoDescriptionText}>
          {description}
        </div>
      </div>
    )}
  </div>
)

SecondaryInfo.propTypes = {
  location: PropTypes.string,
  flagIcon: PropTypes.string,
  birth: PropTypes.string,
  phone: PropTypes.string,

  socials: PropTypes.object,
}

export default SecondaryInfo
