import React from 'react'
import PropTypes from 'prop-types'

/* components */
import LinkComponent from 'components/ui/Links/Link'
import FlagCountry from 'components/common/FlagCountry'

/* styles */
import classes from 'pages/Profile/Host/StructureInfo/index.module.scss'

const HostStructure = ({
  structureImage,
  structureName,
  structureLocation,
  structureFlagIcon,
  structureDescription,
  structurePath,
}) => (
  <div className={classes.structureInfoWrapper}>
    <img
      src={structureImage}
      className={classes.structureInfoInfo}
      alt="structure"
    />
    <h2 className={classes.structureInfoName}>
      {structureName}
    </h2>
    <FlagCountry
      locationFull={structureLocation}
      locationAbbr={structureFlagIcon}
    />
    <p className={classes.structureInfoDescription}>
      {structureDescription}
    </p>
    <LinkComponent
      label="Discover Structure"
      size="maxWidth"
      path={structurePath}
    />
  </div>
)

HostStructure.propTypes = {
  structureImage: PropTypes.string,
  structureName: PropTypes.string,
  structureLocation: PropTypes.string,
  structureFlagIcon: PropTypes.string,
  structureDescription: PropTypes.string,
  structurePath: PropTypes.string,
}

export default HostStructure
