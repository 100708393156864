export const advantages = [
  {
    image: 'https://fakeimg.pl/80/',
    title: 'Advantage #1',
    text: 'Some text some text some text some text some text some text some text some text some text some text some text some text some text some text some text some text',
  },
  {
    image: 'https://fakeimg.pl/80/',
    title: 'Advantage #2',
    text: 'Some text some text some text some text some text some text some text some text some text some text some text some text some text some text some text some text',
  },
  {
    image: 'https://fakeimg.pl/80/',
    title: 'Advantage #3',
    text: 'Some text some text some text some text some text some text some text some text some text some text some text some text some text some text some text some text',
  },
]
