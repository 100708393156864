import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Structure from './Structure'
import SearchBook from './SearchBook'
import classes from './OverviewHeader.module.scss'

const OverviewHeader = (
  {
    itemsStructure,
  },
) => {

  const renderOverviewStructure = useMemo(() => itemsStructure.map(({ ...props }) => (
    <Structure
      key={`items-${props.id}`}
      {...props}
    />
  )), [itemsStructure])

  return (
    <div className={classes.overviewHeader}>
      {renderOverviewStructure}
      <SearchBook />
    </div>

  )
}

OverviewHeader.propTypes = {
  itemsStructure: PropTypes.array,
}

OverviewHeader.defaultProps = {
  itemsStructure: [],
}

export default OverviewHeader
