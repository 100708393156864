import React from 'react'
import PropTypes from 'prop-types'
import Rating from 'react-rating'

import Icon from 'components/common/Icon'

const StarsRating = ({ rating }) => (
  <Rating
    fullSymbol={<Icon iconFolder="stars" iconName="star-full" />}
    emptySymbol={<Icon iconFolder="stars" iconName="star-empty" />}
    fractions={2}
    initialRating={rating}
    readonly
  />
)

StarsRating.propTypes = {
  rating: PropTypes.number.isRequired,
}

export default StarsRating
