import { toast } from 'react-toastify'

import reqH from 'utils/http-client/request-handler'
import { USER_ACTIONS } from 'store/constants'

import { history } from 'store'
import routes from 'constants/routes'

export const signIn = (data) => (dispatch) => {
  reqH.client.post('login', data)
    .then((res) => {
      const userData = res.data.result

      dispatch({
        type: USER_ACTIONS.SIGN_IN,
        payload: userData,
      })

      toast.success(`welcome ${userData.first_name}`)
    })
    .catch((error) => {
      if (error.response.status === 422) {
        // eslint-disable-next-line no-console
        console.log('error.response.data.validation', error.response.data.validation)
      }
    })
}

export const signOut = () => (dispatch) => {
  dispatch({
    type: USER_ACTIONS.SIGN_OUT,
  })

  history.push(routes.home)
}

export const forgotPassword = (data) => {
  reqH.client.post('forgot-password', data)
    .then(() => {
      history.push(routes.signIn[0])
    })
    .catch((error) => {
      if (error.response.status === 422) {
        // eslint-disable-next-line no-console
        console.log('error.response.data.validation', error.response.data.validation)
      }
    })
}

export const resetPassword = (data) => {
  reqH.client.post('reset-password', data)
    .then(() => {
      history.push(routes.signIn[0])
    })
    .catch((error) => {
      if (error.response.status === 422) {
        // eslint-disable-next-line no-console
        console.log('error.response.data.validation', error.response.data.validation)
      }
    })
}

export const prefillFacebook = (data) => (/* dispatch */) => {
  reqH.client.post('auth/social/prefill', data)
    .then((res) => {

      const userData = res.data.result.user
      console.log('userData prefill', userData)

      // dispatch(facebookAuth(data))

    })
    .catch((error) => {
      console.log('prefill error', error)
    })
}

export const facebookAuth = (data) => (dispatch) => {
  reqH.client.post('auth/social/login', data)
    .then((res) => {
      const userData = res.data.result

      dispatch({
        type: USER_ACTIONS.SIGN_IN,
        payload: userData,
      })

    })
    .catch(() => {
      dispatch(prefillFacebook(data))
      // history.push(`${routes.signUp[0]}?facebookToken=${data.token}`)
    })
}


export const getProfile = (userID) => (dispatch) => {
  reqH.client.get(`users/${userID}/profile`)
    .then((res) => {

      const data = res.data.result
      const location = data.location.location.coordinates
      const profile = {}

      profile.id = data.id
      profile.completeness = data.completeness
      profile.references = data.references
      profile.coordinates = { lat: location[1], lng: location[0] }
      profile.facebook = data.facebook
      profile.twitter = data.twitter
      profile.instagram = data.instagram
      profile.skype = data.skype
      profile.website = data.website

      dispatch({
        type: USER_ACTIONS.GET_PROFILE,
        payload: profile,
      })

    })
}
