import React, { useState } from 'react'

/* components */
import Input from 'components/ui/Inputs/Base'

/* styles */
import classes from 'pages/CreateHost/IncomeEstimation/PriceBlock/index.module.scss'

const percent = 10

const PriceBlock = () => {

  const [originalPrice, setOriginalPrice] = useState(0)

  const handleChange = (e) => {
    let value = parseInt(e.target.value)

    if (typeof value !== 'number') {
      return
    }
    if (value < 0 || value > 999999) {
      return
    }

    if (Number.isNaN(value)) {
      value = 0
    }

    setOriginalPrice(value)
  }

  const calculatePercentage = (partialValue, percent) => (partialValue * percent) / 100
  const fee = calculatePercentage(originalPrice, percent)
  const totalPrice = originalPrice + fee

  return (
    <div className={classes.priceBlockWrapper}>
      <div className={classes.priceBlockTop}>
        <h2 className={classes.priceBlockTopTitle}>
          Estimate Your Income
        </h2>
        <Input
          id="price"
          type="number"
          label="Price paid by the volunteer (including package and options)"
          placeholder="€ Price"
          name="price"
          onChange={handleChange}
          className="d-flex align-items-center flex-row justify-content-between"
          value={originalPrice || ''}
          size="small"
        />
        <div className={classes.priceBlockFeesWrapper}>
          <div className={classes.priceBlockFeesText}>
            TWAM Free (%{percent})
          </div>
          <div className={classes.priceBlockFeesAmount}>
            € {fee.toFixed(2)}
          </div>
        </div>
      </div>

      <div className={classes.priceBlockLow}>
        <div className={classes.priceBlockLowTitle}>
          Your estimated income
        </div>
        <h3 className={classes.priceBlockLowTotalPrice}>
          € {totalPrice.toFixed(2)}
        </h3>
      </div>
    </div>
  )
}

export default PriceBlock
