import React, { useMemo } from 'react'
import Slider from 'react-slick'

/* components */
import Mission from 'pages/sheared/Missions/Mission'
import Icon from 'components/common/Icon'

/* constants */
import { LG_DOWN, MD_DOWN, SM_DOWN } from 'constants/screen-sizes'

const Carousel = ({ items }) => {
  const PrevArrow = (props) => {
    const { className, onClick } = props

    return (
      <div onClick={onClick} className={className} role="button">
        <Icon iconFolder="arrows" iconName="prev" />
      </div>
    )
  }

  const NextArrow = (props) => {
    const { className, onClick } = props

    return (
      <div onClick={onClick} className={className} role="button">
        <Icon iconFolder="arrows" iconName="next" />
      </div>
    )
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: LG_DOWN,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: MD_DOWN,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: SM_DOWN,
        settings: {
          centerMode: true,
          centerPadding: '15px',
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  }

  const renderCarouselItems = useMemo(() => items.map((itemProps, index) => (
    <Mission
      key={index}
      {...itemProps}
    />
  )), [items])

  return (
    <Slider {...sliderSettings}>
      {renderCarouselItems}
    </Slider>
  )
}

export default Carousel
