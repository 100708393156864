import React from 'react'
import { Route, Switch } from 'react-router-dom'

/* pages */
import Profile from 'dashboard/Profile'
import Settings from 'dashboard/Settings'

import NotFoundPage from 'pages/NotFoundPage'

const DashboardModel = (props) => {
  const parentUrl = props.match.url

  return (
    <Switch>
      <Route exact path={`${parentUrl}/profile`} component={Profile} />
      <Route exact path={`${parentUrl}/settings`} component={Settings} />

      <Route exact path="*" component={NotFoundPage} />
    </Switch>
  )
}


export default DashboardModel
