import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import classes from './Link.module.scss'


const LinkComponent = (
  {
    path,
    label,
    size,
    color,
    position,
  },
) => (
  <div className={clsx(position, classes[size], 'd-flex')}>
    <NavLink to={path} className={clsx(classes.wrapperLink, classes[size], classes[color])}>
      {label}
    </NavLink>
  </div>
)

LinkComponent.propTypes = {
  path: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'maxWidth', 'custom']),
  color: PropTypes.oneOf(['primaryColor', 'secondaryColor', 'custom']),
  position: PropTypes.oneOf(['justify-content-start', 'justify-content-center', 'justify-content-end']),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
}

LinkComponent.defaultProps = {
  path: '',
  size: 'medium',
  color: 'primaryColor',
  position: 'justify-content-start',
  label: '',
}

export default LinkComponent
