import React from 'react'
import PropTypes from 'prop-types'

/* utils */
import MQ from 'utils/mq'

/* styles */
import classes from 'pages/Mission/Cost/FullPackageItem/index.module.scss'

const FullPackageItem = ({
  image,
  price,
  age,
  days,
}) => (
  <div className={classes.wrapper}>
    <MQ.MD_UP>
      <div className={classes.imageWrapper}>
        <img
          src={image}
          className={classes.image}
          alt="package"
        />
      </div>
    </MQ.MD_UP>
    <div className={classes.infoWrapper}>
      <h2 className={classes.price}>
        €&nbsp;{price.toFixed(2)}
      </h2>
      <h4 className={classes.age}>
        {age}
      </h4>
      <h4 className={classes.days}>
        {days} days
      </h4>
    </div>
  </div>
)

FullPackageItem.propTypes = {
  image: PropTypes.string,
  price: PropTypes.number,
  age: PropTypes.string,
  days: PropTypes.number,
}

export default FullPackageItem
