import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

/* components */
import BaseAccordion from 'components/common/Accordion'
import Icon from 'components/common/Icon'

/* styles */
import classes from './index.module.scss'

const QuestionAnswer = ({ question, answer }) => {
  const { t } = useTranslation('common')

  const [open, setOpen] = useState(false)

  const handleChange = () => {
    setOpen((event) => !event)
  }

  return (
    <BaseAccordion
      onClick={handleChange}
      title={(
        <div className={classes.accordionPanelHead}>
          <h4 className="">
            {t(question)}
          </h4>

          <Icon
            iconFolder="arrows"
            iconName="arrow-right"
            className={clsx(classes.accordionPanelToggler, open && classes.open)}
          />
        </div>
      )}
      text={(
        <div className={classes.accordionPanelBody}>
          {t(answer)}
        </div>
      )}
    />
  )
}

QuestionAnswer.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
}

export default QuestionAnswer
