import React from 'react'

/* components */
import Icon from 'components/common/Icon'

/* utils */
import MQ from 'utils/mq'

import classes from 'pages/Mission/Header/Sharer/index.module.scss'

const Sharer = () => (
  <div className={classes.wrapper}>
    <div className={classes.iconWrapper}>
      <Icon iconName="share" />
    </div>
    <MQ.SM_UP>
      <div className={classes.textWrapper}>
        Share
      </div>
    </MQ.SM_UP>
  </div>
)


export default Sharer
