import React from 'react'

/* components */
import ModalWrapper from 'components/common/Modal'
import SignIn from 'components/common/Auth/SignIn'
import SignUp from 'components/common/Auth/SignUp'

/* styles */
import classes from 'components/partials/Header/ActionsBlock/Unauthorized/index.module.scss'

const UnauthorizedActions = () => (
  <div className={classes.wrapper}>
    <ModalWrapper
      modalName="sign-in"

      headerTitle="Sign In"

      buttonText="Sign In"
      buttonClasses="mr-4"
      buttonSpacing="mr-4"
      buttonSize="small">
      <SignIn />
    </ModalWrapper>

    <ModalWrapper
      modalName="sign-up"
      headerTitle="Sign Up"

      buttonText="Sign Up"
      buttonSize="small">
      <SignUp />
    </ModalWrapper>
  </div>
)

export default UnauthorizedActions
