import React from 'react'

/* components */
import SectionTitle from 'components/ui/SectionTitle'
import Step from 'pages/CreateHost/Steps/Step'

/* configs */
import { steps } from 'pages/CreateHost/Steps/configs'

/* styles */
import classes from './index.module.scss'

const Steps = () => {

  const renderSteps = () => steps.map((itemProps, index) => (
    <Step
      key={index}
      {...itemProps}
    />
  ))

  return (

    <section className={classes.stepsSection}>
      <div className={classes.stepsSectionWrapper}>
        <div className="container medium">
          <div className={classes.stepsSectionInner}>

            <SectionTitle item="How It Works?" />

            <div className={classes.stepsListWrapper}>
              {renderSteps()}
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

export default Steps
