import React from 'react'
import CustomTabs from 'modules/pages/Mission/sections/BodyMission/Tabs'


const BodyMission = () => (
  <section>
    <div className="container">
      <CustomTabs />
    </div>
  </section>

)

export default BodyMission
