import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './Banner.module.scss'

const Banner = (
  {
    background,
    spacing,
    borderRadius,
    children,
  },
) => (
  <section
    className={clsx(classes.sectionBanner, classes[borderRadius], classes[spacing])}
    style={{ backgroundImage: `url(${background})` }}>
    <div className="container">
      {children}
    </div>
  </section>
)

Banner.propTypes = {
  spacing: PropTypes.oneOf(['large', 'medium', 'custom']),
  borderRadius: PropTypes.oneOf(['primaryRadius', 'defaultRadius', 'custom']),
  children: PropTypes.element,
}

Banner.defaultProps = {
  className: '',
  spacing: 'large',
  borderRadius: 'primaryRadius',
}

export default Banner
