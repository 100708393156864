import React from 'react'
import { Route, Switch } from 'react-router-dom'

/* pages */
import HomePage from 'pages/Home'
import HomePageOld from 'modules/pages/Home'

import ThemePage from 'pages/Theme'

import CreateHostPage from 'pages/CreateHost'

import HelpCenterPage from 'pages/HelpCenter'

import ProfileHostPage from 'pages/Profile/Host'

import ProfileVolunteerPage from 'pages/Profile/Volunteer'

import StructurePage from 'pages/Structure'

import MissionPage from 'pages/Mission'
import MissionOld from 'modules/pages/Mission'

import NotFoundPage from 'pages/NotFoundPage'
import UnderConstructionPage from 'pages/UnderConstructionPage'

/* routes-model */
import DashboardModel from 'routes/dashboard-model'
/* routes-types */
import OnlyAuthorizedRoute from 'routes/only-authorized-route'
import OnlyUnauthorizedRoute from 'routes/only-unauthorized-route'

/* constants */
import routes from 'constants/routes'

const AppModel = () => (
  <Switch>
    <Route exact path={routes.home} component={HomePage} />
    <Route exact path="/old-home" component={HomePageOld} />

    <Route exact path={routes.theme} component={ThemePage} />

    <Route exact path={routes.createHost} component={CreateHostPage} />

    <Route exact path={routes.helpCenter} component={HelpCenterPage} />

    <Route exact path={routes.profileVolunteer} component={ProfileVolunteerPage} />

    <Route exact path={routes.profileHost} component={ProfileHostPage} />

    <Route exact path={routes.structure} component={StructurePage} />

    <Route exact path={routes.mission} component={MissionPage} />
    <Route exact path="/old-mission" component={MissionOld} />

    <OnlyUnauthorizedRoute path={routes.signUp} component={UnderConstructionPage} />
    <OnlyUnauthorizedRoute path={routes.signIn} component={UnderConstructionPage} />

    <OnlyAuthorizedRoute path={routes.dashboard} component={DashboardModel} />

    <Route exact path="*" component={NotFoundPage} />
  </Switch>
)

export default AppModel
