import React, { useMemo } from 'react'

/* components */
import DoubleOffer from 'components/ui/Offers/DoubleOffer'

/* utils */
import MQ from 'utils/mq'

/* styles */
import classes from 'pages/Mission/Package/index.module.scss'

const MissionPackage = ({ missionPackage }) => {

  const renderPackageItems = useMemo(() => missionPackage.items.map(({ title, description }, index) => (
    <div key={index} className={classes.textWrapper}>
      <h4 className={classes.title}>{title}</h4>
      <div className={classes.description} dangerouslySetInnerHTML={{ __html: description }} />
    </div>

  )), [missionPackage.items])

  const renderPackagePictures = useMemo(() => missionPackage.pictures.map(({ image }, index) => (
    <img
      key={index}
      className={classes.image}
      src={image}
      alt=""
    />
  )), [missionPackage.pictures])

  return (
    <>
      <section className={classes.sectionWrapper}>
        <main className={classes.main}>
          {renderPackageItems}
        </main>
        <MQ.SM_UP>
          <aside className={classes.aside}>
            {renderPackagePictures}
          </aside>
        </MQ.SM_UP>
      </section>

      <section className={classes.sectionOffers}>
        <DoubleOffer />
      </section>
    </>
  )
}

export default MissionPackage
