export default {
  en: {
    overviewTab: 'Overview',
    packageTab: 'Package',
    RequirementsTab: 'Requirements',
    CostTab: 'Cost',
    OptionTab: 'Option',
  },
  fr: {
    overviewTab: 'Aperçu',
    packageTab: 'Paquet',
    RequirementsTab: 'Exigences',
    CostTab: 'Coût',
    OptionTab: 'Option',
  },
  de: {},
}
