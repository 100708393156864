import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

/* styles */
import classes from 'pages/sheared/Banner/index.module.scss'

const Banner = ({
  backgroundUrl,

  spacing,
  rounded,

  children,
}) => (
  <section
    className={clsx(classes.sectionBanner, classes[spacing], rounded && classes.rounded)}
    style={{ backgroundImage: `url(${backgroundUrl})` }}>
    <div className="container">
      {children}
    </div>
  </section>
)

Banner.propTypes = {
  backgroundUrl: PropTypes.string.isRequired,

  spacing: PropTypes.oneOf(['large', 'medium', 'custom']),
  rounded: PropTypes.bool,

  children: PropTypes.element,
}

Banner.defaultProps = {
  spacing: 'large',
  rounded: false,
}

export default Banner
