import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './Breadcrumb.module.scss'
import BreadcrumbLink from './Link'


const Breadcrumb = (
  {
    items,
  },
) => {
  const renderBreadcrumb = useMemo(() => items.map(({ ...props }) => (
    <BreadcrumbLink
      key={`link-${props.id}`}
      {...props}
    />
  )), [items])

  return (
    <ul className={clsx(classes.navBreadcrumb, 'd-flex')}>
      {renderBreadcrumb}
    </ul>
  )
}

Breadcrumb.propTypes = {
  items: PropTypes.array,
}

Breadcrumb.defaultProps = {
  items: [],
}

export default Breadcrumb
