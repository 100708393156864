export default {
  en: {
    TwamMissionTitle: 'TWAM’s Mission',
    TwamMissionDescription: 'The Twaming platform facilitate the connection between those who want to share some knowledge, skills or experiences (called Twamers) and those who want to receive them (called Twamhosts).',
    TwamMissionLink: 'Learn More',
  },
  fr: {
    TwamMissionTitle: 'Mission de TEAM',
  },
  de: {},

}
