import React from 'react'

/* components */
import SectionTitle from 'components/ui/SectionTitle'
import FlagCountry from 'components/common/FlagCountry'
import FavoritesToggler from 'pages/Mission/Header/FavoritesToggler'
import Sharer from 'pages/Mission/Header/Sharer'

/* utils */
import MQ from 'utils/mq'

/* styles */
import classes from 'pages/Mission/Header/index.module.scss'

import Breadcrumb from 'components/ui/Breadcrumb'

const BreadcrumbConfig = [
  {
    id: 0,
    name: 'Theme name',
    link: '',
  },
  {
    id: 1,
    name: 'Subtheme Name',
    link: 'Link',
  },
]

const MissionHeader = ({
  title,
  location,
  flagIcon,
  volunteersCount,
}) => (
  <div className={classes.wrapper}>

    <div className={classes.topRow}>
      <Breadcrumb items={BreadcrumbConfig} />
    </div>

    <SectionTitle
      item={title}
      position="text-start"
      spacing="mb-4"
    />

    <div className={classes.bottomRow}>

      <MQ.SM_UP>
        <div className={classes.bottomRowInfo}>
          <FlagCountry
            locationFull={location}
            locationAbbr={flagIcon}
            spacing="mb-0"
          />
          <div className={classes.bottomRowVolunteersCount}>
            {volunteersCount} volunteer
          </div>
        </div>
        <div className={classes.bottomRowActions}>
          <FavoritesToggler />
          <Sharer />
        </div>
      </MQ.SM_UP>

      <MQ.SM_DOWN>
        <div className={classes.bottomRowLocation}>
          <FlagCountry
            locationFull={location}
            locationAbbr={flagIcon}
            spacing="mb-0"
          />
        </div>
        <div className={classes.bottomRowActionsWrapper}>
          <div className={classes.bottomRowVolunteersCount}>
            {volunteersCount} volunteer
          </div>

          <div className={classes.bottomRowActions}>
            <FavoritesToggler />
            <Sharer />
          </div>
        </div>
      </MQ.SM_DOWN>

    </div>
  </div>
)

export default MissionHeader
