import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

/* components */
import Button from 'components/ui/Button/base'
import Icon from 'components/common/Icon'

/* hooks */
import _useCustomEventListener from 'hooks/use-custom-event-listener'

/* constants */
import { modalVisibilityActions } from 'components/common/Modal/constants'

/* styles */
import classes from 'components/common/Modal/index.module.scss'

const ModalWrapper = ({
  modalName,

  buttonVariant,
  buttonText,
  buttonSpacing,
  buttonSize,
  buttonIconName,

  children,

  additionalOnOpenFunction,
}) => {

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)

    if (additionalOnOpenFunction && typeof additionalOnOpenFunction === 'function') {
      additionalOnOpenFunction()
    }
  }

  const handleClose = () => setOpen(false)

  _useCustomEventListener(`${modalVisibilityActions.open}${modalName}`, handleOpen)
  _useCustomEventListener(`${modalVisibilityActions.close}${modalName}`, handleClose)

  return (
    <>
      <Button
        type="button"
        text={buttonText}
        onClick={handleOpen}
        variant={buttonVariant}
        spacing={buttonSpacing}
        size={buttonSize}
        nameIcon={buttonIconName}
      />

      <Modal
        dialogClassName={classes.modalDialog}
        show={open}
        onHide={handleClose}>
        <div className={classes.modalHeader}>
          <div className={classes.modalCloseButton} onClick={handleClose} role="button">
            <Icon iconName="close" />
          </div>
        </div>
        <div className={classes.modalBody}>
          {children}
        </div>
      </Modal>
    </>
  )
}


ModalWrapper.propTypes = {
  modalName: PropTypes.string.isRequired,

  buttonVariant: PropTypes.string,
  buttonText: PropTypes.string,
  buttonSpacing: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonIconName: PropTypes.string,

  children: PropTypes.element,

  additionalOnOpenFunction: PropTypes.func,
}

export default ModalWrapper
