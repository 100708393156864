import React from 'react'

/* components */
import DoubleOffer from 'components/ui/Offers/DoubleOffer'

/* styles */
import classes from 'pages/Mission/Requirements/index.module.scss'

const MissionRequirements = ({ missionRequirements }) => (
  <section className={classes.sectionWrapper}>

    <div className={classes.descriptionWrapper}>
      <div className={classes.description} dangerouslySetInnerHTML={{ __html: missionRequirements }} />
    </div>

    <DoubleOffer />

  </section>
)

export default MissionRequirements
