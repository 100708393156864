import React from 'react'
import SelectComponent from 'components/ui/Select/Base'
import Input from 'components/ui/Inputs/Base'
import { useFormik } from 'formik'
import clsx from 'clsx'
import Button from 'components/ui/Button/base'
import DropdownCount from 'components/ui/Dropdown/Count'
import SectionTitle from 'components/ui/SectionTitle'
import { useTranslation } from 'react-i18next'
import { options } from './config'
import classes from './MissionSearch.module.scss'


const MissionSearch = () => {
  const { t } = useTranslation('common')

  const form = useFormik({
    initialValues: {
      countAdults: 1,
      countChildren: 0,
      // [formFields.location]: '',
    },
    onSubmit: () => {
    },
  })
  return (
    <div className={classes.wrapperMissionSearch}>
      <form onSubmit={form.handleSubmit}>
        <SectionTitle
          item={t('missionSearchTitle')}
          variant="white"
        />

        <div className="d-flex justify-content-between">
          <div className={classes.blockWithSelect}>
            <SelectComponent
              label={t('ThemeLabel')}
              options={options}
            />
          </div>
          <div className={classes.blockWithInput}>
            <Input
              id="location"
              onChange={form.handleChange}
              label={t('locationLabel')}
              labelColor="white"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <div className={clsx(classes.blockWithInput, classes.small)}>
              <Input
                id="arrival"
                onChange={form.handleChange}
                label={t('ArrivalLabel')}
                labelColor="white"
                placeholder="Enter date"
              />
            </div>
            <div className={clsx(classes.blockWithInput, classes.small)}>
              <Input
                id="departure"
                onChange={form.handleChange}
                label={t('DepartureLabel')}
                labelColor="white"
                placeholder="Enter date"
                value="Dec 29th, 2019"
              />
            </div>
          </div>
          <div className={classes.blockWithSelect}>
            <DropdownCount
              label={t('NumberOfVolunteersLabel')}
              onChange={form.handleChange}
              values={form.values}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-end">
          <div className={classes.blockWithSelect}>
            <SelectComponent
              label={t('ThemeLabel')}
              options={options}
            />
          </div>
          <div className={classes.blockWithBtn}>
            <Button
              text={t('StartSearchingButton')}
              size="MissionSearchBtn"
            />
          </div>
        </div>
      </form>
    </div>
  )
}

MissionSearch.propTypes = {}

MissionSearch.defaultProps = {}

export default MissionSearch
