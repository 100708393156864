import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, ReactReduxContext } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { I18nextProvider } from 'react-i18next'
import { ConnectedRouter } from 'connected-react-router'

/* store */
import { store, persistor, history } from 'store'

/* translates */
import translates from 'translates'

/* application */
import App from 'App'

/* env */
import { ENV } from 'constants/main'

/* styles */
import 'styles/index.scss'

// TODO remove after all old svgs will be deleted
import { ReactComponent as Sprite } from 'assets/sprite.svg'

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <PersistGate loading={null} persistor={persistor}>

      <I18nextProvider i18n={translates}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <Sprite />
          <App />
        </ConnectedRouter>
      </I18nextProvider>

    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)

if (ENV === 'development' && module.hot) {
  module.hot.accept()
}
