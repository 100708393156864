import React from 'react'
import PropTypes from 'prop-types'
import DropdownComponent from '../Base'
import Header from './Header'
import Menu from './Menu'


const DropdownCount = (
  {
    onChange,
    values,
    label,
    colorLabel,
  },
) => (
  <DropdownComponent
    colorLabel={colorLabel}
    label={label}
    header={<Header countChildren={values.countChildren} countAdults={values.countAdults} />}
    menu={<Menu onChange={onChange} countChildren={values.countChildren} countAdults={values.countAdults} />}
  />
)

DropdownCount.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  colorLabel: PropTypes.string,
  label: PropTypes.string,
}

DropdownCount.defaultProps = {
  values: {},
}

export default DropdownCount
