import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

/* components */
import Icon from 'components/common/Icon'

/* styles */
import classes from 'components/common/VerificationStatus/index.module.scss'

const VerificationStatus = ({ verified, spacing }) => {

  const { t } = useTranslation('common')

  return (
    <div className={clsx(classes.verificationStatusWrapper, classes[spacing])}>
      <div className={classes.verificationStatusIconWrapper}>
        {verified ?
          <Icon iconFolder="verification" iconName="verified" />
          :
          <Icon iconFolder="verification" iconName="unverified" />}
      </div>

      <div className={classes.verificationStatusTextWrapper}>
        {verified ?
          t('isVerified')
          :
          t('isNotVerified')}
      </div>
    </div>
  )
}

VerificationStatus.propTypes = {
  verified: PropTypes.bool.isRequired,
  spacing: PropTypes.oneOf(['mb-4']),
}

VerificationStatus.defaultProps = {
  verified: false,
}

export default VerificationStatus
