export const profileVolunteer = {
  role: 'volunteer',
  verification: true,
  name: 'Amanda Lawson',
  firstName: 'Amanda',
  avatar: 'https://fakeimg.pl/250x100/',

  flagIcon: 'us',
  location: 'Alajuela, Costa Rica',
  birth: 'June 16th, 1989',
  phone: '336868458464',
  description: 'Don’t buy souvenirs or other items made from critically endangered hawksbill shell. When traveling, ask vendors what souvenirs are made of and don’t purchase items in question.',

  socials: {
    linkedin: {
      name: 'linkedin',
      link: 'https://www.linkedin.com/',
    },
    instagram: {
      name: 'instagram',
      link: 'https://www.instagram.com/?hl=ru',
    },
  },
}

export const missions = [
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Animal Rescue Centre Supporter',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
    reviewsCount: 228,
    rating: 4.5,
    reviewsLink: '',
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Teaching English to Monks',
    flagIcon: 'us',
    locationTitle: 'Kathmandu, Nepal',
    reviewsCount: 228,
    rating: 4.5,
    reviewsLink: '',
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Great White Shark Conservation',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
    reviewsCount: 228,
    rating: 4.5,
    reviewsLink: '',
  },
  {
    imgUrl: 'https://fakeimg.pl/270x160/',
    imgName: 'Mission image',
    title: 'Natural Horsemanship Guestranch',
    flagIcon: 'us',
    locationTitle: 'Alajuela, Costa Rica',
    reviewsCount: 228,
    rating: 4.5,
    reviewsLink: '',
  },
]

export const comments = [
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Hanna Kenter',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Adison George',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey. Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Jaylon Bator',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
  },
  {
    avatar: 'https://fakeimg.pl/250x100/',
    name: 'Wilson Mango',
    missionName: 'Mission Name',
    text: 'Climate change affects the health of coral reefs which are vital to the hawksbills survival. A warming planet also skews sex ratios in baby turtles, changes the abundance and distribution of prey.',
  },
]
