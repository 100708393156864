import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import classes from './Title.module.scss'

const SectionTitle = (
  {
    item,
    variant,
    spacing,
    position,
  },
) => {
  const { t } = useTranslation('common')

  return (
    <h1 className={clsx(classes.title, classes[position], classes[spacing], classes[variant])}>
      {t(item)}
    </h1>
  )
}


SectionTitle.propTypes = {
  item: PropTypes.string,
  variant: PropTypes.oneOf(['main', 'white']),
  spacing: PropTypes.oneOf(['mb-12', 'mb-4', 'my-12']),
  position: PropTypes.oneOf(['text-start', 'text-center']),
}

SectionTitle.defaultProps = {
  item: 'Section title',
  variant: 'main',
  spacing: 'mb-12',
  position: 'text-center',
}

export default SectionTitle
