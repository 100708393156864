import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

/* constants */
import routes from 'constants/routes'

const OnlyUnauthorizedRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      user && user.authorization
        ? <Redirect to={{ pathname: routes.home, state: { from: props.location } }} />
        : <Component {...props} />
    )}
  />
)

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(OnlyUnauthorizedRoute)
