import React from 'react'
import PropTypes from 'prop-types'

/* styles */
import classes from 'pages/Mission/Options/Option/index.module.scss'

const Option = ({
  image,
  title,
  description,
  price,
  option,
}) => (
  <div className={classes.wrapper}>
    <div className={classes.imageWrapper}>
      <img
        className={classes.image}
        src={image}
        alt="option"
      />
    </div>
    <div className={classes.infoWrapper}>
      <h2 className={classes.title}>
        {title}
      </h2>
      <p className={classes.description}>
        {description}
      </p>
    </div>
    <div className={classes.priceWrapper}>
      <h3 className={classes.price}>
        €&nbsp;{price}
      </h3>
      <h4 className={classes.option}>
        {option}
      </h4>
    </div>
  </div>
)

Option.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  option: PropTypes.string,
}

export default Option
