import React from 'react'
import PropTypes from 'prop-types'

/* styles */
import classes from './index.module.scss'

const Step = ({ title, text, img }) => (
  <div className={classes.stepWrapper}>
    <img
      className={classes.stepImage}
      src={img}
      alt="step"
    />

    <div className={classes.stepContentWrapper}>
      <div className={classes.stepTitle}>
        {title}
      </div>
      <p className={classes.stepText}>
        {text}
      </p>
    </div>
  </div>
)

Step.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
}

export default Step
