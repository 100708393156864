import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import routes from 'constants/routes'

/* components */
import StarsRating from 'components/common/StarsRating'

/* styles */
import classes from 'pages/sheared/Testimonials/Testimonial/index.module.scss'

const Testimonial = ({
  rating,
  title,
  text,
  img,
  name,
  date,
}) => (
  <div className={classes.testimonialWrapper}>
    <div className={classes.testimonialRating}>
      <StarsRating rating={rating} />
    </div>
    <div className={classes.testimonialTitle}>
      {title}
    </div>
    <p className={classes.testimonialText}>
      {text}
    </p>
    <Link to={routes.profileVolunteer} className="d-flex align-items-center">
      <img
        className={classes.testimonialOwnerAvatar}
        src={img}
        alt="testimonial"
      />
      <div>
        <div className={classes.testimonialOwnerName}>
          {name}
        </div>
        <div className={classes.testimonialDate}>
          {date}
        </div>
      </div>
    </Link>
  </div>
)

Testimonial.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  rating: PropTypes.number,
}

Testimonial.defaultProps = {}

export default Testimonial
