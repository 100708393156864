import React from 'react'
import clsx from 'clsx'

/* components */
import AdvantagesCarousel from 'pages/CreateHost/Advantages/AdvantagesCarousel'

/* styles */
import classes from 'pages/CreateHost/Advantages/index.module.scss'

const Advantages = ({ items }) => (
  <section className={clsx('advantagesCarouselSection', classes.advantagesCarouselSection)}>
    <div className={classes.advantagesCarouselSectionWrapper}>
      <div className="container">
        <div className={classes.advantagesCarouselSectionInner}>

          <AdvantagesCarousel items={items} />

        </div>
      </div>
    </div>
  </section>
)

export default Advantages
