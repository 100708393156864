export default {
  en: {
    isVerified: 'Verified',
    isNotVerified: 'Unverified',
  },
  fr: {
    isVerified: 'Vérifié',
    isNotVerified: 'Non vérifié',
  },
  de: {
    isVerified: 'Verifiziert',
    isNotVerified: 'Nicht überprüft',
  },
}
