import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, Tab } from 'react-bootstrap'
import clsx from 'clsx'
import classes from './Tabs.module.scss'
import { overviewHeader } from '../../../config'
import Overview from '../Overview'

const CustomTabs = () => {
  const { t } = useTranslation('common')

  const [key, setKey] = useState('overview')

  return (

    <Tabs
      className={classes.tabs}
      activeKey={key}
      onSelect={(k) => setKey(k)}>
      <Tab
        tabClassName={clsx(classes.tab, key === 'overview' ? classes.active : null)}
        eventKey="overview"
        title={t('overviewTab')}>
        <Overview itemsStructure={overviewHeader} />
      </Tab>
    </Tabs>
  )
}

CustomTabs.propTypes = {}

CustomTabs.defaultProps = {}

export default CustomTabs
