import React from 'react'
import { Link } from 'react-router-dom'

/* constants */
import routes from 'constants/routes'

/* styles */
import classes from 'components/partials/Header/Logo/index.module.scss'

/* assets */
import logoImage from 'assets/images/logo.png'

const Logo = () => (
  <div className={classes.wrapper}>
    <Link className={classes.link} to={routes.home}>
      <div className={classes.imageWrapper}>
        <img
          className={classes.image}
          src={logoImage}
          alt="logo"
        />
      </div>
      <div className={classes.textWrapper}>
        <div className={classes.text}>
          Volunteering platform
        </div>
      </div>
    </Link>
  </div>
)

export default Logo
