import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'

/* components */
import Input from 'components/ui/Inputs/Base'
import PasswordInput from 'components/ui/Inputs/Password'
import Button from 'components/ui/Button/base'
import SectionTitle from 'components/ui/SectionTitle'
import FacebookAuth from 'components/common/Auth/sheared/FacebookAuth'
import Icon from 'components/common/Icon'

/* actions */
import { sendRemoteSignUpForm } from 'components/common/Auth/SignUp/actions'

/* utils */
import customEvent from 'utils/custom-event'

/* constants */
import { modalVisibilityActions } from 'components/common/Modal/constants'

/* configs */
import { formFields } from 'components/common/Auth/SignUp/configs'

/* styles */
import classes from 'components/common/Auth/SignUp/index.module.scss'

const SignUpForm = ({ sendRemoteSignUpForm }) => {

  // TODO make Modal reducer instead of this
  // const openSignUpModal = () => customEvent.trigger(`${modalVisibilityActions.open}sign-up`)
  const closeSignUpModal = () => customEvent.trigger(`${modalVisibilityActions.close}sign-up`)

  const [role, setRole] = useState('')

  const _renderRoleChoice = () => (
    <div className={classes.roleChoiceWrapper}>
      <SectionTitle item="Sign Up" />

      <div className={classes.roleChoiceDisclaimer}>
        On our platform you can register
        as individual (volunteer) or as a structure (host).
        <br />
        <br />
        Choose right function below.
      </div>

      <div className={classes.roleChoiceVolunteer}>
        <Button
          onClick={() => setRole('volunteer')}
          type="button"
          text="I am a Volunteer"
          size="large"
          color="btn-primary"
          spacing="mb-20"
        />
      </div>
      <div className={classes.roleChoiceHost}>
        <Button
          onClick={() => setRole('host')}
          type="button"
          text="I am a Host"
          size="large"
          color="btn-primary"
        />
      </div>
    </div>
  )

  const formik = useFormik({
    initialValues: {
      [formFields.email]: '',
      [formFields.password]: '',
      [formFields.passwordConfirmation]: '',
    },

    validationSchema: Yup.object({
      [formFields.email]: Yup.string().email().required(),
      [formFields.password]: Yup.string().min(8).required(),
      [formFields.passwordConfirmation]: Yup.string().oneOf([Yup.ref('password'), null], 'passwords does not match').required('password confirmation is required field'),
    }),

    onSubmit: (values, { resetForm }) => {
      const newValues = {
        role,
        ...values,
      }
      sendRemoteSignUpForm(newValues)

      resetForm()

      closeSignUpModal()
    },
  })

  const _renderForm = (formik) => {
    const hasError = (fieldName) => formik.touched[fieldName] && formik.errors[fieldName]
    const emailHasError = hasError(formFields.email)
    const passwordHasError = hasError(formFields.password)
    const passwordConfirmationHasError = hasError(formFields.passwordConfirmation)

    const capitalize = (str) => {
      if (typeof str !== 'string') return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    }

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.goBackButton} onClick={() => setRole('')} role="button">
          <Icon iconFolder="arrows" iconName="prev" />
        </div>
        <SectionTitle item={`Sign Up As ${capitalize(role)}`} />

        <FacebookAuth
          type="signup"
          callback={(res) => { console.log('res', res) }}
        />

        <Input
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.email}
          name={formFields.email}
          label={formFields.email}
          placeholder="Enter email"
          type={formFields.email}

          error={emailHasError ? formik.errors.email : null}
          errorInput={emailHasError ? 'errorInput' : null}
          errorLabel={emailHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <PasswordInput
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.password}
          name={formFields.password}
          label={formFields.password}
          placeholder="Enter password"
          showVisibilityToggler={false}

          error={passwordHasError ? formik.errors.password : null}
          errorInput={passwordHasError ? 'errorInput' : null}
          errorLabel={passwordHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <PasswordInput
          value={formik.values.passwordConfirmation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}

          id={formFields.passwordConfirmation}
          name={formFields.passwordConfirmation}
          label="Confirm password"
          placeholder="Confirm password"
          showVisibilityToggler={false}

          error={passwordConfirmationHasError ? formik.errors.passwordConfirmation : null}
          errorInput={passwordConfirmationHasError ? 'errorInput' : null}
          errorLabel={passwordConfirmationHasError ? 'errorLabel' : null}

          spacing="mb-5"
        />

        <Button
          text="Sign Up"
          onClick={formik.handleSubmit}
          disabled={!role || !formik.isValid || !formik.values.email || !formik.values.password || !formik.values.passwordConfirmation}

          type="submit"
          size="large"
          color="btn-primary"
          spacing="mb-6"
        />

      </form>
    )
  }

  return !role ?
    _renderRoleChoice()
    :
    _renderForm(formik)
}

const actionsToProps = { sendRemoteSignUpForm }

export default connect(null, actionsToProps)(SignUpForm)
