import React from 'react'

import LinkComponent from 'components/ui/Links/Link'
import SectionTitle from 'components/ui/SectionTitle'

/* styles */
import classes from './index.module.scss'

const TwamMission = () => (
  <div className={classes.bannerWrapper}>
    <div className={classes.bannerInner}>
      <SectionTitle
        item="TWAM’s Mission"
        variant="white"
        spacing="mb-4"
      />
      <div className={classes.bannerText}>
        The Twaming platform facilitate the connection between those who want to share some knowledge, skills or experiences (called Twamers) and those who want to receive them (called Twamhosts).
      </div>
      <LinkComponent
        path=""
        label="Learn More"
        position="justify-content-center"
      />
    </div>
  </div>
)

export default TwamMission
