import React from 'react'

import SectionTitle from 'components/ui/SectionTitle'

/* styles */
import classes from 'pages/CreateHost/MainBannerInner/index.module.scss'


const MainBannerInner = () => (
  <div className={classes.bannerWrapper}>
    <div className={classes.bannerInner}>
      <p className={classes.bannerText}>
        You want to receive volunteers inside your organization?
        <br />
        <br />
        You want to provide package, which can become income generating activities for your organization?
      </p>
      <SectionTitle
        item="Don’t wait anymore, create a free profile!"
        variant="white"
        spacing="mb-4"
      />
    </div>
  </div>
)

export default MainBannerInner
