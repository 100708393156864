import React from 'react'
import image from 'assets/images/offers.jpg'
import classes from './DoubleOffer.module.scss'

const DoubleOffer = () => (
  <div className="d-flex justify-content-between flex-wrap">
    <a href="/" target="_blank">
      <div className={classes.blockWithImage}>
        <img className={classes.image} src={image} alt="offer" />
      </div>
    </a>
    <a href="/" target="_blank">
      <div className={classes.blockWithImage}>
        <img className={classes.image} src={image} alt="offer" />
      </div>
    </a>
  </div>
)

DoubleOffer.propTypes = {}

DoubleOffer.defaultProps = {}

export default DoubleOffer
