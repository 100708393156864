import axios from 'axios'

import { store } from 'store'

import { API_URL } from 'constants/main'

const body = document.getElementsByTagName('body')

class RequestHandler {

  constructor() {
    this.client = axios.create({ baseURL: API_URL })

    this.client.interceptors.request.use(
      (config) => {
        const state = store.getState()

        let token = null

        if (state) {
          if (state.user && state.user.authorization) {
            token = `${state.user.authorization.token_type} ${state.user.authorization.access_token}`
          }
        }

        config.headers.Authorization = token

        if (config.method === 'get') {
          body[0].classList.add('loading-overlay')
        }

        return config
      },
    )

    this.client.interceptors.response.use(
      (response) => {

        if (response.data.messages && response.data.messages.length) {
          const message = response.data.messages[0].text
          // eslint-disable-next-line no-console
          console.log('message', message)
        }

        body[0].classList.remove('loading-overlay')

        return response
      },
      (error) => {

        body[0].classList.remove('loading-overlay')

        throw error
      },
    )
  }

}

const requestHandler = new RequestHandler()

export default requestHandler
