import React from 'react'
import clsx from 'clsx'

/* components */
import SectionTitle from 'components/ui/SectionTitle'
import Carousel from 'pages/Theme/MissionsCarousel/Carousel'

/* configs */
import { missions } from 'pages/Home/configs'

/* styles */
import classes from 'pages/Theme/MissionsCarousel/index.module.scss'
import 'pages/Theme/MissionsCarousel/slider.scss'

const MissionsCarousel = ({ title }) => (
  <section className={clsx('missionsCarouselSection', classes.missionsCarouselSection)}>
    <div className={classes.missionsCarouselSectionWrapper}>
      <div className="container">
        <div className={classes.missionsCarouselSectionInner}>

          <SectionTitle item={title} position="text-start" />

          <Carousel items={missions} />

        </div>
      </div>
    </div>
  </section>
)

export default MissionsCarousel
