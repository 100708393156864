import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

/* components */
import SectionTitle from 'components/ui/SectionTitle'
import Mission from 'pages/sheared/Missions/Mission'

/* styles */
import classes from 'pages/sheared/Missions/index.module.scss'

const Missions = ({ items, title, titlePosition, titleSpacing }) => {
  const renderMissions = useMemo(() => items.map((itemProps, index) => (
    <Mission
      key={index}
      {...itemProps}
    />
  )), [items])

  return (
    <section className={classes.missionsSection}>
      <div className={classes.missionsSectionWrapper}>
        <div className="container">
          <div className={classes.missionsSectionInner}>

            <SectionTitle
              item={title}
              position={titlePosition}
              spacing={titleSpacing}
            />
            <div className={classes.missionsListWrapper}>
              {renderMissions}
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}

Missions.propTypes = {
  items: PropTypes.array.isRequired,

  title: PropTypes.string,
  titlePosition: PropTypes.string,
  titleSpacing: PropTypes.string,
}

export default Missions
