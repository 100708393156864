import React from 'react'

/* components */
import MainInfo from 'pages/Profile/sheared/MainInfo'
import SecondaryInfo from 'pages/Profile/sheared/SecondaryInfo'
import Resume from 'pages/Profile/Volunteer/Resume'
import Missions from 'pages/sheared/Missions'
import Comments from 'components/common/Comments'

/* configs */
import { profileVolunteer, missions, comments } from 'pages/Profile/Volunteer/configs'

/* styles */
import classes from 'pages/Profile/Volunteer/index.module.scss'

const ProfileVolunteerPage = () => (
  <section className={classes.profileVolunteerSection}>
    <div className={classes.profileVolunteerSectionWrapper}>
      <div className="container">
        <div className={classes.profileVolunteerSectionInner}>

          <div className={classes.profileVolunteerSectionInfoWrapper}>
            <MainInfo
              avatar={profileVolunteer.avatar}
              name={profileVolunteer.name}
              firstName={profileVolunteer.firstName}

              role={profileVolunteer.role}
              verification={profileVolunteer.verification}
            />

            <div className={classes.profileVolunteerSectionSecondaryInfoWrapper}>
              <SecondaryInfo
                location={profileVolunteer.location}
                flagIcon={profileVolunteer.flagIcon}
                birth={profileVolunteer.birth}
                phone={profileVolunteer.phone}

                socials={profileVolunteer.socials}

                description={profileVolunteer.description}
              />

              <Resume />
            </div>
          </div>

          <Missions
            title="Missions Visited"
            items={missions}
          />

          <Comments
            comments={comments}
          />

        </div>
      </div>
    </div>
  </section>
)

export default ProfileVolunteerPage
