import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

/* components */
import Icon from 'components/common/Icon'

/* hooks */
import _useToggle from 'hooks/use-toggle'

/* actions */
import { signOut } from 'store/actions/user'

/* styles */
import classes from 'components/partials/Header/ActionsBlock/Authorized/DropDown/index.module.scss'


const UserDropDown = ({ toggleParentDropDown, signOut }) => {

  const items = [
    {
      name: 'profile',
      text: 'My Profile',
      path: '1',
    },
    {
      name: 'account',
      text: 'Account',
      nestedItems: [
        {
          name: 'personal-info',
          text: 'Personal Info',
          path: '2.1',
        },
        {
          name: 'structure',
          text: 'Structure',
          path: '2.2',
        },
        {
          name: 'volunteers',
          text: 'Volunteers',
          path: '2.3',
        },
        {
          name: 'missions',
          text: 'Missions',
          path: '2.4',
        },
        {
          name: 'revenues',
          text: 'Revenues',
          path: '2.5',
        },
      ],
    },
    {
      name: 'settings',
      text: 'Settings',
      path: '3',
    },
    {
      name: 'help-center',
      text: 'Help Center',
      path: '4',
    },
    {
      name: 'sign-out',
      text: 'Sign Out',
      action: signOut,
    },
  ]

  const [isNestedOpened, toggleNested] = _useToggle(false)

  const renderDropDownItemInner = (item) => (
    <div className={classes.itemInnerWrapper}>
      <div className={classes.icon}>
        <Icon iconFolder="user-dropdown" iconName={item.name} />
      </div>
      <div className={classes.text}>
        {item.text}
      </div>
    </div>
  )

  const renderDropDownItems = (items) => items.map((item, index) => (
    <li key={index} className={classes.itemWrapper}>
      {item.path && (
        <Link to={item.path} className={classes.itemInner} onClick={toggleParentDropDown}>
          {renderDropDownItemInner(item)}
        </Link>
      )}

      {item.action && (
        <div onClick={item.action} className={classes.itemInner} role="button">
          {renderDropDownItemInner(item)}
        </div>
      )}

      {item.nestedItems && item.nestedItems.length && (
        <>
          <div className={clsx(classes.itemInner, classes.itemInnerNested)} onClick={toggleNested} role="button">
            {renderDropDownItemInner(item)}
            <Icon iconFolder="arrows" iconName="arrow-dropdown" />
          </div>
          {isNestedOpened && (
            <ul className={clsx(classes.dropdown, classes.dropdownNested)}>
              {renderDropDownItems(item.nestedItems)}
            </ul>
          )}
        </>
      )}
    </li>
  ))

  return (
    <ul className={classes.dropdown}>
      {renderDropDownItems(items)}
    </ul>
  )
}

const actionsToProps = { signOut }

export default connect(null, actionsToProps)(UserDropDown)
