import React from 'react'
import { connect } from 'react-redux'

/* components */
import MailTo from 'components/partials/sheared/MailTo'
import NavLinks from 'components/partials/sheared/NavLinks'
import Logo from 'components/partials/Header/Logo'
import UnauthorizedActions from 'components/partials/Header/ActionsBlock/Unauthorized'
import AuthorizedActions from 'components/partials/Header/ActionsBlock/Authorized'

/* utils */
import MQ from 'utils/mq'

/* styles */
import classes from 'components/partials/Header/index.module.scss'

// import TmpMenu from './tmp-menu'

const Header = ({ userData }) => (
  <header className={classes.header}>
    {/* <TmpMenu /> */}
    <div className={classes.topHeaderWrapper}>
      <div className="container">
        <div className={classes.topHeaderInner}>

          <MQ.LG_UP>
            <MailTo />
          </MQ.LG_UP>

          <div className={classes.topHeaderRightSide}>
            <NavLinks />
          </div>

        </div>
      </div>
    </div>

    <div className={classes.lowHeaderWrapper}>
      <div className="container">
        <div className={classes.lowHeaderInner}>

          <Logo />

          {userData.id ? (
            <AuthorizedActions userData={userData} />
          ) : (
            <MQ.SM_UP>
              <UnauthorizedActions />
            </MQ.SM_UP>
          )}

        </div>
      </div>
    </div>
  </header>
)

const mapStateToProps = (state) => ({
  userData: state.user,
})

export default connect(mapStateToProps)(Header)
