import React, { useMemo } from 'react'
import Breadcrumb from 'components/ui/Breadcrumb'
import ThemeTitle from './ThemeTitle'
import { BreadcrumbConfig } from '../../config'

const ThemeHeader = (
  { items },
) => {
  const renderThemeTitle = useMemo(() => items.map(({ ...props }) => (
    <ThemeTitle
      key={`mission-${props.id}`}
      {...props}
    />
  )), [items])

  return (
    <section>
      <div className="container">
        <Breadcrumb items={BreadcrumbConfig} />
        {renderThemeTitle}
      </div>
    </section>
  )
}

ThemeHeader.propTypes = {}

ThemeHeader.defaultProps = {}

export default ThemeHeader
