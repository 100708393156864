import React from 'react'
import PropTypes from 'prop-types'

/* styles */
import classes from './index.module.scss'

const Advantage = ({ image, title, text }) => (
  <div className={classes.advantageWrapper}>
    <img
      className={classes.advantageImage}
      src={image}
      alt=""
    />
    <div className={classes.advantageTitle}>
      {title}
    </div>
    <div className={classes.advantageText}>
      {text}
    </div>
  </div>
)

Advantage.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default Advantage
