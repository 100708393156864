import React, { useMemo } from 'react'

/* components */
import Option from 'pages/Mission/Options/Option'
import DoubleOffer from 'components/ui/Offers/DoubleOffer'

/* styles */
import classes from 'pages/Mission/Options/index.module.scss'

const MissionOptions = ({ missionOptions }) => {
  const renderOptions = useMemo(() => missionOptions.map((itemProps, index) => (
    <Option
      key={index}
      {...itemProps}
    />
  )), [missionOptions])

  return (
    <section className={classes.sectionWrapper}>

      <div className={classes.optionsWrapper}>
        {renderOptions}
      </div>

      <DoubleOffer />

    </section>
  )
}

export default MissionOptions
