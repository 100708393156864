import routes from 'constants/routes'

export const menuItems = [
  {
    id: 0,
    label: 'Volunteering',
    path: '/test',
  },
  {
    id: 1,
    label: 'SolidarityTripes',
    path: '/',
  },
  {
    id: 2,
    label: 'Projects',
    path: '/',
  },
  {
    id: 3,
    label: 'Events',
    path: '/',
  },
  {
    id: 4,
    label: 'Sensitization',
    path: '/',
  },
  {
    id: 5,
    label: 'Support',
    path: '/',
  },
  {
    id: 6,
    label: 'Blog',
    path: '/',
  },
  {
    id: 7,
    label: 'Help center',
    path: routes.helpCenter,
  },
]
