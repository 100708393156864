import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Social from 'components/ui/Links/Social'


const GroupSocial = (
  {
    social,
    spacing,
  },
) => {
  const renderGroupSocialData = useMemo(() => Object.values(social).map((key) => {
    const data = [key]

    return data.map((name, index) => (
      <Social
        key={`info-${index}`}
        link={name.link}
        variant={name.name}
        img={name.name}
        icon={name.name}
        spacing={spacing}
      />
    ))
  }), [social, spacing])

  return (
    <div className="d-flex">
      {renderGroupSocialData}
    </div>
  )
}

GroupSocial.propTypes = {
  social: PropTypes.object,
  spacing: PropTypes.string,
}

GroupSocial.defaultProps = {
  spacing: 'mr-4',
  social: {},
}

export default GroupSocial
