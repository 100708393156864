import React from 'react'
import Banner from 'components/ui/Banner'
import MissionSearch from 'modules/pages/Home/sections/MissionSearch'
import background from 'assets/images/banners/banner_homepage_bg.jpg'

const HomePage = () => (
  <Banner background={background}>
    <MissionSearch />
  </Banner>
)

export default HomePage
