import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import SpriteIcon from 'components/icons/SpriteIcon'
import clsx from 'clsx'
import classes from './CustomBaseDropdown.module.scss'


// Custom Dropdown toggle
const CustomToggle = forwardRef(({ children, onClick }, ref) => {
  const [inactive, setInactive] = useState(true)

  const handleClick = (event) => {
    onClick(event)
  }

  useEffect(() => {
    setInactive((value) => !value)
  }, [ref, children, onClick])

  return (
    <a
      href="/"
      ref={ref}
      onClick={(event) => {
        event.preventDefault()
        handleClick(event)
      }}

      className={clsx(classes.formControl, { [classes.Show]: inactive }, 'd-flex justify-content-between align-items-center')}>
      {children}
      <SpriteIcon name="arrow-down-v2" size="w-20px" colorIcon="#8D8D8D" />
    </a>
  )
})


const DropdownComponent = (
  {
    label,
    header,
    menu,
    colorLabel,
  },
) => (
  <div>
    <div className={clsx(classes.labelStyles, classes[colorLabel])}>
      <span>
        {label}
      </span>
    </div>
    <Dropdown className={clsx(classes.baseDropdown)}>
      <Dropdown.Toggle as={CustomToggle}>
        {header}
      </Dropdown.Toggle>

      <Dropdown.Menu className={classes.dropdownMenu}>
        {menu}
      </Dropdown.Menu>
    </Dropdown>
  </div>
)

DropdownComponent.propTypes = {
  label: PropTypes.string,
  header: PropTypes.element,
  menu: PropTypes.element,
  colorLabel: PropTypes.oneOf(['black', 'white']),
}

DropdownComponent.defaultProps = {
  colorLabel: 'white',
}

export default DropdownComponent
