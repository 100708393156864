import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

/* constants */
import routes from 'constants/routes'

const OnlyAuthorizedRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      user && user.authorization
        ? <Component {...props} />
        : <Redirect to={{ pathname: routes.signIn[0], state: { from: props.location } }} />
    )}
  />
)

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(OnlyAuthorizedRoute)
