import React from 'react'
import ThemeHeader from './sections/ThemeHeader'
import { ThemeTitleConfig } from './config'
import BodyMission from './sections/BodyMission'
import classes from './Mission.module.scss'

const Mission = () => (
  <main className={classes.mission}>
    <ThemeHeader items={ThemeTitleConfig} />
    <BodyMission />
  </main>
)

export default Mission
