import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import clsx from 'clsx'

/* styles */
import classes from 'components/common/Tabs/index.module.scss'

const CustomTabs = ({ tabs, defaultSelectedTab }) => {

  if (!defaultSelectedTab) {
    defaultSelectedTab = tabs[0].name
  }

  const [tabKey, setTabKey] = useState(defaultSelectedTab)

  const renderTabs = () => tabs.map((tab, index) => (
    <Tab
      key={index}
      tabClassName={clsx(classes.tab, tabKey === tab.name && classes.active)}
      eventKey={tab.name}
      title={tab.title}>
      {tab.body}
    </Tab>
  ))

  return (
    <Tabs
      id=""
      className={classes.tabs}
      activeKey={tabKey}
      onSelect={(k) => setTabKey(k)}>
      {renderTabs()}
    </Tabs>
  )
}

CustomTabs.propTypes = {}

CustomTabs.defaultProps = {}

export default CustomTabs
