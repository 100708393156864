import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'

/* components */
import Icon from 'components/common/Icon'

/* styles */
import classes from 'pages/sheared/Categories/Category/index.module.scss'

const Category = ({
  name,
  link,
  title,
}) => (
  <div className={classes.categoryWrapper}>
    <NavLink to={link} className={classes.categoryLink}>

      <Icon iconName={name} iconFolder="categories" />
      <div className={clsx(classes.categoryTitle, 'ml-8')}>
        {title}
      </div>

    </NavLink>
  </div>
)

Category.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
}

export default Category
