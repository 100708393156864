import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Icon from 'components/common/Icon'

/* styles */
import classes from 'components/common/FlagCountry/index.module.scss'

const FlagCountry = ({
  locationFull,
  locationAbbr,
  spacing,
}) => (
  <div className={clsx(classes.locationWrapper, classes[spacing])}>

    <div className={classes.locationIcon}>
      <Icon
        iconFolder="flags"
        iconName={locationAbbr}
        extension="png"
        size="18x14"
      />
    </div>

    <h4 className={classes.locationText}>
      {locationFull}
    </h4>

  </div>
)

FlagCountry.propTypes = {
  locationFull: PropTypes.string,
  locationAbbr: PropTypes.string,
  spacing: PropTypes.oneOf(['mb-6', 'mb-0']),
}

FlagCountry.defaultProps = {
  spacing: 'mb-6',
}

export default FlagCountry
