import React from 'react'

/* components */
import Input from 'components/ui/Inputs/Base'
import Icon from 'components/common/Icon'

/* hooks */
import _useToggle from 'hooks/use-toggle'

/* styles */
import classes from 'components/ui/Inputs/Password/index.module.scss'

const PasswordInput = (props) => {

  const { value, showVisibilityToggler = true } = props

  const [isVisible, toggleVisibility] = _useToggle(false)

  return (
    <div className={classes.wrapper}>
      <Input
        type={isVisible ? 'text' : 'password'}
        {...props}
      />
      {showVisibilityToggler && (
        <div
          className={classes.visibilityToggler}
          onClick={toggleVisibility}
          role="button">

          {value && value.length && (
            <Icon iconName="show" className={isVisible ? classes.visible : classes.hidden} />
          )}
        </div>
      )}
    </div>
  )
}

export default PasswordInput
