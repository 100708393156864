import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './Video.module.scss'


const Video = (
  {
    spacing,
  },
) => (
  // TODO refactoring
  <div className={clsx(classes.blockWithVideo, classes[spacing], 'd-flex')}>
    <img className={classes.video} src="https://fakeimg.pl/500x300/" alt="qweqweqwe" />
  </div>
)

Video.propTypes = {
  spacing: PropTypes.oneOf(['mb-8']),
}

Video.defaultProps = {
  spacing: null,
}

export default Video
