import React from 'react'
import PropTypes from 'prop-types'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

/* components */
import Icon from 'components/common/Icon'

/* constants */
import { FACEBOOK_APP_ID } from 'constants/main'

/* styles */
import classes from 'components/common/Auth/sheared/FacebookAuth/index.module.scss'

const FacebookAuth = ({ type, callback, onClickFunction }) => (
  <FacebookLogin
    appId={FACEBOOK_APP_ID}
    version={3.2}
    redirectUri={window.location.href.split('?')[0]}
    scope="public_profile, email"
    callback={callback}
    onClick={onClickFunction}
    render={(renderProps) => (
      <button
        type="button"
        className={classes.facebookButton}
        onClick={renderProps.onClick}>
        <Icon iconName="facebook-v2" />
        <span className={classes.facebookButtonText}>
          {type === 'signin' && 'Sign In '}
          {type === 'signup' && 'Sign Up '}
          via Facebook
        </span>
      </button>
    )}
  />
)

FacebookAuth.propTypes = {
  type: PropTypes.oneOf(['signin', 'signup']),
  callback: PropTypes.func,
  onClickFunction: PropTypes.func,
}

export default FacebookAuth
