import React from 'react'
import PropTypes from 'prop-types'

/* components */
import StarsRating from 'components/common/StarsRating'

/* styles */
import classes from 'components/common/Comments/Comment/index.module.scss'

const Comment = ({
  avatar,
  name,
  missionName,
  text,

  hasRating,
  rating,
}) => (
  <div className={classes.commentWrapper}>
    <div className={classes.commentTopWrapper}>
      <div className={classes.commentInfoWrapper}>
        <div className={classes.commentInfoAvatarWrapper}>
          <img
            className={classes.commentInfoAvatar}
            src={avatar}
            alt="avatar"
          />
        </div>
        <div>
          <h4 className={classes.commentInfoName}>
            {name}
          </h4>
          <h5 className={classes.commentInfoMissionName}>
            {missionName}
          </h5>
        </div>
      </div>

      {hasRating && (
        <div className={classes.commentRating}>
          <StarsRating rating={rating} />
        </div>
      )}
    </div>

    <p className={classes.commentText}>
      {text}
    </p>
  </div>
)

Comment.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  missionName: PropTypes.string,
  text: PropTypes.string,
  rating: PropTypes.number,
}

Comment.defaultProps = {
  rating: 0,
}

export default Comment
