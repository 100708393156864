import React from 'react'
import PropTypes from 'prop-types'

/* constants */
import { ASSETS_URL } from 'constants/main'

/* styles */
import classes from 'components/common/DynamicImage/index.module.scss'

const DynamicImage = ({ imageData, variant, size }) => {

  let variation = imageData.default
  if (variant) {
    variation = variant
  }

  const addDefaultSrc = (e) => {
    e.target.src = `https://fakeimg.pl/${size}?text=default`
  }

  return (
    <img
      className={classes.dynamicImage}
      src={`${ASSETS_URL}/${imageData.path}_${variation}_mdpi.${imageData.extension}`}
      alt="media"
      onError={addDefaultSrc}
    />
  )
}

DynamicImage.propTypes = {
  imageData: PropTypes.shape({
    default: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
  }).isRequired,
  variant: PropTypes.oneOf(['mini', 'medium']),
  size: PropTypes.string,
}

export default DynamicImage
