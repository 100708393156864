import React, { useMemo } from 'react'
import classes from './index.module.scss'


const Blocks = (
  {
    items,
    title,
  },
) => {
  const renderLink = useMemo(() => items.map(({ id, name, link }) => (
    <li key={`item-${id}`} className={classes.item}>
      <a className={classes.link} href={link} target="_blank" rel="noopener noreferrer">{name}</a>
    </li>
  )), [items])


  return (
    <div className={classes.blockWithVolunteering}>
      <h3 className={classes.title}>{title}</h3>
      <ul className={classes.list}>
        {renderLink}
      </ul>
    </div>
  )
}

Blocks.propTypes = {}

Blocks.defaultProps = {}

export default Blocks
