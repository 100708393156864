import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import SpriteIcon from 'components/icons/SpriteIcon'
import classes from './Button.module.scss'

const Button = (
  {
    Btn,
    color,
    variant,
    text,
    size,
    spacing,
    onClick,
    disabled,
    type,
    nameIcon,
    sizeIcon,
  },
) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={clsx(classes[color], spacing, classes[size], classes[Btn], classes[variant], 'd-flex align-items-center')}
    onClick={onClick}
    disabled={disabled}>
    {nameIcon && <SpriteIcon name={nameIcon} className={classes.icon} size={classes[sizeIcon]} />}
    <span className={classes.text}>
      {text}
    </span>
  </button>
)

Button.propTypes = {
  color: PropTypes.string,
  Btn: PropTypes.string,
  text: PropTypes.string,
  spacing: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'outlinePrimary', 'danger', 'secondary', 'success', 'primaryNotBg', 'custom', 'circle', 'disableCircle', 'facebook']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'customSize', 'MissionSearchBtn', 'circleSize']),
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  nameIcon: PropTypes.string,
}

Button.defaultProps = {
  type: 'submit',
  Btn: 'customBtn',
  variant: 'primary',
  size: 'large',
  spacing: '',
  sizeIcon: '',
}

export default Button
