import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import routes from 'constants/routes' // TODO delete after links are reworked

/* components */
import FlagCountry from 'components/common/FlagCountry'
import StarsRating from 'components/common/StarsRating'

/* styles */
import classes from 'pages/sheared/Missions/Mission/index.module.scss'

const Mission = ({
  // link,  // TODO uncomment after links are reworked

  imgUrl,
  imgName,

  title,

  flagIcon,
  locationTitle,

  isEnd,

  rating,
  reviewsCount,
  reviewsLink,
}) => (
  <div className={classes.missionWrapper}>
    {/* <Link to={link} className={classes.missionLink}> */}
    <Link to={routes.mission} className={classes.missionLink}>
      <div className={clsx(classes.mission, isEnd && classes.ended)}>
        <div className={classes.missionImageWrapper}>
          <img
            className={classes.missionImage}
            src={imgUrl}
            alt={imgName}
          />
        </div>
        <h4 className={classes.missionTitle}>
          {title}
        </h4>
        <FlagCountry
          locationFull={locationTitle}
          locationAbbr={flagIcon}
        />
      </div>
    </Link>

    {rating && reviewsCount && (
      <div className={classes.missionBottomBlock}>
        <StarsRating rating={rating} />
        <Link to={reviewsLink}>
          <span className={classes.missionReviewsCount}>{reviewsCount} reviews</span>
        </Link>
      </div>
    )}

  </div>
)

Mission.propTypes = {
  // link: PropTypes.string,    // TODO uncomment after links are reworked
  imgUrl: PropTypes.string,
  imgName: PropTypes.string,
  title: PropTypes.string,
  flagIcon: PropTypes.string,
  locationTitle: PropTypes.string,
  isEnd: PropTypes.bool.isRequired,
}

Mission.defaultProps = {
  // link: '',  // TODO uncomment after links are reworked
  imgUrl: 'https://fakeimg.pl/270x160/',
  imgName: '',
  title: '',
  flagIcon: 'us',
  locationTitle: '',
  isEnd: false,
}

export default Mission
